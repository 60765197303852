/*global jQuery, Monogram, console */

Monogram.ComparePage = {
    updateFromItemCount: function (table) {
        // if called with no argument, find all compare tables in the
        // document and call this method on each of them.
        if (!table) {
            document.querySelectorAll('[data-compare-table]').forEach(function (table) {
                this.updateFromItemCount(table);
            }.bind(this));
            return;
        }

        var count = this.getItemCount(table);
        var countText;

        table.setAttribute('data-compare-item-count', String(count));

        if (count === 1) {
            countText = '1 Product';
        } else {
            countText = count + ' Products';
        }
        table.querySelectorAll('[data-compare-number-of-products]').forEach(function (element) {
            element.innerHTML = countText;
        });

        if (count < 5) {
            table.querySelectorAll('[data-compare-maximum-products-notice]').forEach(function (element) {
                element.classList.add('hide');
            });
        } else {
            table.querySelectorAll('[data-compare-maximum-products-notice]').forEach(function (element) {
                element.classList.remove('hide');
            });
        }

        var resultsURL = sessionStorage.getItem('monogramCompareResultsURL');

        if (resultsURL) {
            table.querySelectorAll('[data-compare-add-products]').forEach(function (element) {
                element.setAttribute('href', resultsURL);
            });
            if (count < 5) {
                table.querySelectorAll('[data-compare-add-products-enabled]').forEach(function (element) {
                    element.classList.remove('hide');
                });
                table.querySelectorAll('[data-compare-add-products-disabled]').forEach(function (element) {
                    element.classList.add('hide');
                });
            } else {
                table.querySelectorAll('[data-compare-add-products-enabled]').forEach(function (element) {
                    element.classList.add('hide');
                });
                table.querySelectorAll('[data-compare-add-products-disabled]').forEach(function (element) {
                    element.classList.remove('hide');
                });
            }
        } else {
            table.querySelectorAll('[data-compare-add-products]').forEach(function (element) {
                element.removeAttribute('href');
            });
            table.querySelectorAll('[data-compare-add-products-enabled]').forEach(function (element) {
                element.classList.add('hide');
            });
            table.querySelectorAll('[data-compare-add-products-disabled]').forEach(function (element) {
                element.classList.remove('hide');
            });
        }
    },
    recomputeDifferences: function (table) {
        // if called with no argument, find all compare tables in the
        // document and call this method on each of them.
        if (!table) {
            document.querySelectorAll('[data-compare-table]').forEach(function (table) {
                this.recomputeDifferences(table);
            }.bind(this));
            return;
        }

        table.querySelectorAll('tr.data').forEach(function (tr) {
            var hasDifferences = false;
            var valueArray = [];
            var valueHash = {};
            tr.querySelectorAll('td:not(:first-child)').forEach(function (td) {
                var value = td.innerHTML.trim();
                value = value.replace(/\s+/g, ' ');
                if (!(value in valueHash)) {
                    valueHash[value] = true;
                    valueArray.push(value);
                }
            });
            if (valueArray.length >= 2) {
                tr.classList.add('has-differences');
            } else {
                tr.classList.remove('has-differences');
            }
        });
    },
    getItemCount: function (table) {
        return table.querySelectorAll('[data-compare-product]').length;
    },
    toggleHighlights: function (table, flag) {
        // if called with no argument, find all compare tables in the
        // document and call this method on each of them.
        if (!table) {
            document.querySelectorAll('[data-compare-table]').forEach(function (table) {
                this.toggleHighlights(table, flag);
            }.bind(this));
            return;
        }

        if (flag) {
            table.classList.add('highlighting-differences');
            table.querySelectorAll('[data-compare-highlight-differences-wrapper]').forEach(function (wrapper) {
                wrapper.classList.add('hide');
            });
            table.querySelectorAll('[data-compare-remove-highlights-wrapper]').forEach(function (wrapper) {
                wrapper.classList.remove('hide');
            });
        } else {
            table.classList.remove('highlighting-differences');
            table.querySelectorAll('[data-compare-highlight-differences-wrapper]').forEach(function (wrapper) {
                wrapper.classList.remove('hide');
            });
            table.querySelectorAll('[data-compare-remove-highlights-wrapper]').forEach(function (wrapper) {
                wrapper.classList.add('hide');
            });
        }
    },
    computeColumnIndices: function (table) {
        // if called with no argument, find all compare tables in the
        // document and call this method on each of them.
        if (!table) {
            document.querySelectorAll('[data-compare-table]').forEach(function (table) {
                this.computeColumnIndices(table);
            }.bind(this));
            return;
        }

        var count = 1;
        table.querySelectorAll('[data-compare-product]').forEach(function (cell) {
            cell.setAttribute('data-column-index', String(count));
            count += 1;
        });
    },
    removeProduct: function (table, sku) {
        var itemCount = this.getItemCount(table);
        if (itemCount < 2) {
            return;
        }

        if (!(sku instanceof String) && typeof sku !== 'string') {
            sku = sku.getAttribute('data-sku');
        }
        if (!sku) {
            return;
        }

        var heads = Array.from(table.querySelectorAll('[data-compare-product]')).filter(function (td) {
            return td.getAttribute('data-sku') === sku;
        });
        if (!heads.length) {
            return;
        }

        var head = heads[0];    // assuming there's only one

        var columnIndex = Number(head.getAttribute('data-column-index'));
        if (isNaN(columnIndex)) {
            return;
        }

        if (!table.id) {
            table.id = 'table' + (new Date().getTime());
        }

        document.querySelectorAll('#' + table.id + ' > * > tr:not(.heading)').forEach(function (tr) {
            var cell = tr.children[columnIndex]; // child **elements**
            if (!cell) {
                return;
            }
            cell.parentNode.removeChild(cell);
        });

        itemCount = this.getItemCount(table);

        var colspan = itemCount + 1;

        document.querySelectorAll('#' + table.id + ' > tbody > tr.heading > td').forEach(function (headingCell) {
            headingCell.setAttribute('colspan', String(colspan));
        });

        if (itemCount <= 1) {
            table.querySelectorAll('.product-card-B-remove').forEach(function (element) {
                element.classList.add('hide');
            });
        } else {
            table.querySelectorAll('.product-card-B-remove').forEach(function (element) {
                element.classList.remove('hide');
            });
        }

        this.updateFromItemCount(table);
        this.recomputeDifferences(table);
        this.computeColumnIndices(table);
        Monogram.TopScrollBar.recompute();
    },
    addEventListeners: function () {
        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-compare-table] [data-compare-highlight-differences]');
            if (!anchor) {
                return;
            }
            var table = anchor.closest('[data-compare-table]');
            if (!table) {
                return;
            }
            event.preventDefault();
            this.toggleHighlights(table, true);
        }.bind(this));
        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-compare-table] [data-compare-remove-highlights]');
            if (!anchor) {
                return;
            }
            var table = anchor.closest('[data-compare-table]');
            if (!table) {
                return;
            }
            event.preventDefault();
            this.toggleHighlights(table, false);
        }.bind(this));
        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-compare-table] [data-compare-remove-product]');
            if (!anchor) {
                return;
            }
            var table = anchor.closest('[data-compare-table]');
            if (!table) {
                return;
            }
            event.preventDefault();
            this.removeProduct(table, anchor);
        }.bind(this));
    },
    init: function () {
        this.addEventListeners();
        this.updateFromItemCount();
        this.recomputeDifferences();
        this.computeColumnIndices();
    }
};

jQuery(function ($) {
    Monogram.ComparePage.init();
});
