/*global Monogram, jQuery, empty, alert, URLSearchParams, isStaticEnvironment */

/**
 * Compare Checkboxes.
 *
 * On pages that are not Compare.
 */

Monogram.CompareCheckboxes = {

    getPageType: function () {
        var pageType = document.body.getAttribute('data-page-type');
        switch (pageType) {
        case 'compare':
        case 'wishlist':
        case 'product-line-results':
            return pageType;
        default:
            return 'other';
        }
    },

    // For purposes of compare checkboxes, "wishlist" is a product
    // line.
    getProductLine: function () {
        var productLine = document.body.getAttribute('data-product-line');
        if (productLine !== undefined && productLine !== null) {
            return productLine;
        }
        var pageType = this.getPageType();
        switch (pageType) {
        case 'wishlist':
            return pageType;
        default:
            return null;
        }
    },

    getState: function () {
        var pageType = this.getPageType();
        var productLine = this.getProductLine();
        var savedProductLine = sessionStorage.getItem('monogramCompareProductLine');

        var data = sessionStorage.getItem('monogramCompare');
        if (data !== undefined && data !== null) {
            data = JSON.parse(data);
        } else {
            data = {};
        }

        if (productLine) {
            if (productLine === savedProductLine) {
                this.state = data;
            } else {
                this.state = {};
                sessionStorage.removeItem('monogramCompare');
                sessionStorage.setItem('monogramCompareProductLine', productLine);
            }
        } else {
            // don't change stored product line
            this.state = data;
        }
    },

    saveState: function () {
        sessionStorage.setItem('monogramCompare', JSON.stringify(this.state));
    },

    getCount: function () {
        return Object.keys(this.state).length;
    },

    getList: function () {
        var skus = Object.keys(this.state);
        skus.sort();            // just to ensure predictable, consistent order
        return skus;
    },

    add: function (sku) {
        this.state[sku] = true;
        this.saveState();
    },

    remove: function (sku) {
        delete this.state[sku];
        this.saveState();
    },

    has: function (sku) {
        return (sku in this.state) ? this.state[sku] : false;
    },

    clear: function () {
        this.state = {};
        this.saveState();
    },

    getSku: function (checkbox) {
        var sku = checkbox.getAttribute('data-compare');
        if (empty(sku)) {
            sku = checkbox.value;
        }
        return sku;
    },

    setActiveStates: function () {
        document.querySelectorAll('[data-compare]').forEach(function (checkbox) {
            this.setActiveState(checkbox);
        }.bind(this));
    },

    setActiveState: function (checkbox) {
        var label = checkbox.closest('[data-compare-label]') || checkbox.closest('label');
        if (!label) {
            return;
        }
        if (checkbox.checked) {
            label.classList.add('active');
        } else {
            label.classList.remove('active');
        }
    },

    setCompareCheckboxStates: function () {
        document.querySelectorAll('[data-compare]').forEach(function (checkbox) {
            this.setCompareCheckboxState(checkbox);
        }.bind(this));
    },

    setCompareCheckboxState: function (checkbox) {
        var sku = checkbox.value;
        if (this.has(sku)) {
            checkbox.checked = true;
        } else {
            checkbox.false = true;
        }
    },

    getCompareURL: function () {
        var params = new URLSearchParams();
        this.getList().forEach(function (sku) {
            params.append('sku', sku);
        });
        if (isStaticEnvironment()) {
            return 'https://appliances.monogram.com/us/compare?' + params.toString();
        } else {
            return '/us/compare?' + params.toString();
        }
    },

    updateCompareLinks: function () {
        var url = this.getCompareURL();
        document.querySelectorAll('[data-compare-go-to]').forEach(function (anchor) {
            anchor.href = url;
        });

    },

    addEventListeners: function () {
        document.addEventListener('change', function (event) {
            if (!event.target.hasAttribute('data-compare')) {
                return;
            }
            var checked = event.target.checked;
            var sku = this.getSku(event.target);
            if (empty(sku)) {
                return;
            }

            if (checked) {
                if (this.getCount() >= 5) {
                    alert('You cannot compare more than 5 items.');
                    event.target.checked = false;
                    checked = false;
                    event.preventDefault();
                } else {
                    this.add(sku);
                }
            } else {
                this.remove(sku);
            }

            this.updateCompareLinks();

            var otherCheckboxes =
                Array.from(document.querySelectorAll('[data-compare]')).filter(function (checkbox) {
                    return this.getSku(checkbox) === sku;
                }.bind(this));
            otherCheckboxes.forEach(function (checkbox) {
                checkbox.checked = checked;
            });
            this.setActiveStates();

            window.requestAnimationFrame(function () {
                Monogram.EqualHeight.updateEqualHeightCollections();
            });
        }.bind(this));
        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-compare-go-to]');
            if (!anchor) {
                return;
            }
            sessionStorage.setItem('monogramCompareResultsURL', location.href);

            var skuList = this.getList();
            var skuListString = skuList.join(', ');
            var skuCount = skuList.length;
            var skuCountString = String(skuCount);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'compare-now',
                'prodSkusCompared': skuListString, //ZIFS360NHLH, ZIFS360NHRH, etc.
                'numOfProdSkusCompared': skuCountString //2, etc.
            });
        }.bind(this));
    },

    init: function () {
        this.getState();
        this.updateCompareLinks();
        this.addEventListeners();
        this.setCompareCheckboxStates();
        this.setActiveStates();
    }
};

jQuery(function ($) {
    Monogram.CompareCheckboxes.init();
});
