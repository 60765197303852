/*global Monogram, jQuery */

/**
 * Top Nav Search dropdown.
 */

Monogram.TopNavSearch = {
    addEventListeners: function () {
        var $ = jQuery;

        /**
         * Automatic focus when opening search menu.
         */
        $(document).on('shown.bs.collapse', '.top-nav-search', function (event) {
            var input = event.target.querySelector('[type="search"], [type="text"]');
            if (input) {
                input.focus();
            }
        });

        /**
         * Click outside of top nav search to close it.
         */
        document.addEventListener('click', function (event) {
            if (event.target.closest('[data-top-nav-search]')) {
                return;
            }
            $('.collapse[data-top-nav-search]').collapse('hide');
        });

        /**
         * Press [Esc] to close top nav search and top nav mobile menu.
         */
        $(window).on('keydown', function (event) {
            if (event.which === 27 /* Escape key */) {
                $('.collapse[data-top-nav-search]').collapse('hide');
                $('.collapse[data-top-nav--mobile]').collapse('hide');
            }
        });
    },
    init: function () {
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.TopNavSearch.init();
});
