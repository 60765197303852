/*global jQuery, Monogram, console */

/**
 * <div class="fade-scroll" data-fade-scroll="css">
 *
 * Optional attributes:
 *
 * - data-fade-scroll-offset-top - Specify the number of pixels offset
 *   from the top of the screen where any content will become visible.
 *
 *   Default is 0.  Add the height of any sticky top nav.  Also add
 *   the height of any padding and/or interior padding at the top,
 *   after which actual content becomes visible.
 *
 * - data-fade-scroll-offset-bottom - Specify the number of pixels
 *   offset from the top of the screen where any content will become
 *   visible.
 *
 *   Default is 0.  Add the height of any sticky bottom div.  Also add
 *   the height of any padding and/or interior padding at the bottom,
 *   before which actual content becomes visible.
 */
Monogram.FadeScroll = {
    updateClasses: function (element) {
        var rect = element.getBoundingClientRect();
        var scrollTop = window.scrollY || window.pageYOffset;
        var scrollBottom = scrollTop + window.innerHeight;
        var elementTop = rect.top + scrollTop;
        var elementBottom = rect.bottom + scrollTop;

        var offsetTop = parseFloat(element.getAttribute('data-fade-scroll-offset-top')) || 0;
        var offsetBottom = parseFloat(element.getAttribute('data-fade-scroll-offset-bottom')) || 0;
        if (offsetTop || offsetBottom) {
            if ((scrollTop + offsetTop) < (scrollBottom - offsetBottom)) {
                scrollTop += offsetTop;
                scrollBottom -= offsetBottom;
            }
        }
        var addOffset1 = 100;
        var addOffset2 = 0.15 * (offsetBottom - offsetTop);
        var addOffset = Math.min(addOffset1, addOffset2);
        scrollTop += addOffset;
        scrollBottom -= addOffset;

        if (elementTop >= scrollTop && elementBottom < scrollBottom) {
            element.classList.remove('fade-scroll-invisible');
            element.classList.remove('fade-scroll-partially-visible');
            element.classList.add('fade-scroll-visible');
        } else if (elementTop < scrollBottom && elementBottom >= scrollTop) {
            element.classList.remove('fade-scroll-invisible');
            element.classList.remove('fade-scroll-visible');
            element.classList.add('fade-scroll-partially-visible');
        } else {
            element.classList.remove('fade-scroll-partially-visible');
            element.classList.remove('fade-scroll-visible');
            element.classList.add('fade-scroll-invisible');
        }
    },
    updateElement: function (element) {
        var attrValue = element.getAttribute('data-fade-scroll');
        if (attrValue === 'css') {
            this.updateClasses(element);
        }
    },
    update: function () {
        var mq = window.matchMedia('screen and (min-width: ' + Monogram.breakpoints.sm + 'px)');
        if (mq.matches) {
            document.querySelectorAll('[data-fade-scroll]').forEach(function (element) {
                this.updateElement(element);
            }.bind(this));
        } else {
            document.querySelectorAll('[data-fade-scroll]').forEach(function (element) {
                element.style.opacity = 1.0;
            }.bind(this));
        }
    },
    addEventListeners: function () {
        window.addEventListener('scroll', function (event) {
            this.update();
        }.bind(this));
        window.addEventListener('resize', function (event) {
            this.update();
        }.bind(this));
    },
    addSlickEventListeners: function () {
        var $ = jQuery;
        var $document = $(document);
        var handler = function (event, slick) {
            if ('$dots' in slick) { // detect if event is actually on slick object
                this.update();
            }
        }.bind(this);
        $document.on('destroy', handler);
        $document.on('init', handler);
        $document.on('reInit', handler);
        $document.on('setPosition', handler);
        $document.on('swipe', handler);
        $document.on('lazyLoaded', handler);
        $document.on('afterChange', handler);
    },
    init: function () {
        this.addEventListeners();
        this.addSlickEventListeners();
        this.update();
    }
};

jQuery(function ($) {
    Monogram.FadeScroll.init();
});
