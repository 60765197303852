/*global Monogram, jQuery, console, URLSearchParams, alert, encodeEntities, isStaticEnvironment, empty */

Monogram.SearchResults = {
    getSearchResultType: function () {
        var pageType = document.body.getAttribute('data-page-type');
        if (pageType === 'search-results-by-download') {
            return 'download';
        }
        if (pageType === 'search-results-by-page') {
            return 'page';
        }
        if (pageType === 'search-results-by-product') {
            return 'product';
        }
        return null;
    },

    getJsonUrl: function () {
        var resultType;
        if (isStaticEnvironment()) {
            switch (this.getSearchResultType()) {
            case 'download':
                return '/us/literature-search-json';
            case 'page':
                return '/us/page-search-json';
            case 'product':
                return '/us/product-search-json';
            }
        }
        return location.pathname + '-json';
    },

    getSearchParamsObject: function () {
        var params = new URLSearchParams(location.search);
        if (isStaticEnvironment()) {
            switch (this.getSearchResultType()) {
            case 'download':
                if (!params.has('Ntt')) {
                    params.set('Ntt', 'zet');
                }
                break;
            case 'page':
                if (!params.has('Ntt')) {
                    params.set('Ntt', 'refrigerator');
                }
                break;
            case 'product':
                if (!params.has('Ntt')) {
                    params.set('Ntt', 'zet');
                }
                break;
            }
        }
        return params;
    },

    initParams: function () {
        this.jsonUrl = this.getJsonUrl();
        this.searchParams = this.getSearchParamsObject();

        this.pageNumber = 1;
        if (isStaticEnvironment()) {
            // so next click provides **first** page of results
            this.pageNumber = 0;
        }
        var pageNumber;
        if (this.searchParams.has('No')) {
            pageNumber = parseInt(this.searchParams.get('No'), 10);
            if (!isNaN(pageNumber)) {
                this.pageNumber = pageNumber;
            }
        }

        this.resultsPerPage = 12;
        var resultsPerPage;
        if (this.searchParams.has('Nrpp')) {
            resultsPerPage = parseInt(this.searchParams.get('Nrpp'), 10);
            if (!isNaN(resultsPerPage)) {
                this.resultsPerPage = resultsPerPage;
            }
        }

        this.startIndex = (this.pageNumber - 1) * resultsPerPage + 1;
    },

    hideLoadMoreButton: function () {
        document.querySelectorAll('[data-load-more-results-block]').forEach(function (element) {
            element.classList.add('hide-important');
        });
    },

    showLoadMoreButton: function () {
        document.querySelectorAll('[data-load-more-results-block]').forEach(function (element) {
            element.classList.remove('hide-important');
        });
    },

    loadMoreResults: function () {
        this.searchParams.set('No', this.pageNumber + 1);
        var url = this.jsonUrl + '?' + this.searchParams.toString();
        console.log(url);

        Monogram.LoadingSpinner.show();
        this.hideLoadMoreButton();
        delete this.httpResponse;
        delete this.httpJsonResponse;

        this.httpFetch = fetch(url)
            .then(function (response) {
                this.httpResponse = response;
                return response.json();
            }.bind(this))
            .then(function (jsonResponse) {
                this.httpJsonResponse = jsonResponse;
                this.loadMoreResultsSuccess(jsonResponse);
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                alert("We're sorry, we encountered a problem while attempting to retrieve results.  Please try again later.");
                Monogram.LoadingSpinner.hide();
                this.showLoadMoreButton();
            }.bind(this));
    },

    loadMoreResultsSuccess: function (jsonResponse) {
        Monogram.LoadingSpinner.hide();
        var resultType = this.getSearchResultType();
        if (!resultType) {
            this.showLoadMoreButton();
            console.error('Monogram.SearchResults: not a search results page, or invalid [data-page-type] on the <body>');
            return;
        }

        this.pageNumber += 1;

        var totalPages = parseInt(jsonResponse.totalPages, 10);
        var startIndex = parseInt(jsonResponse.resultSet.firstRecord, 10);
        var endIndex   = parseInt(jsonResponse.resultSet.lastRecord, 10);
        var count      = parseInt(jsonResponse.resultSet.count, 10);
        var pageNumber = parseInt(jsonResponse.resultSet.page, 10);

        console.log(JSON.stringify(pageNumber));
        console.log(JSON.stringify(totalPages));

        if (pageNumber === totalPages) {
            this.hideLoadMoreButton();
        } else {
            this.showLoadMoreButton();
        }

        var results = jsonResponse.resultSet.searchResults;
        if (resultType === 'download') {
            this.loadMoreDownloadResults(results);
        } else if (resultType === 'page') {
            this.loadMorePageResults(results);
        } else if (resultType === 'product') {
            this.loadMoreProductResults(results);
        }
    },

    loadMoreDownloadResults: function (results) {
        var searchResultListElement = document.querySelector('[data-search-result-list]');
        if (!searchResultListElement) {
            console.error('Monogram.SearchResults: no result list element');
            return;
        }
        var html = '';
        results.forEach(function (result) {
            html = html + this.downloadResultHtml(result);
        }.bind(this));
        searchResultListElement.innerHTML = searchResultListElement.innerHTML + html;
    },

    loadMorePageResults: function (results) {
        var searchResultListElement = document.querySelector('[data-search-result-list]');
        if (!searchResultListElement) {
            console.error('Monogram.SearchResults: no result list element');
            return;
        }
        var html = '';
        results.forEach(function (result) {
            html = html + this.pageResultHtml(result);
        }.bind(this));
        searchResultListElement.innerHTML = searchResultListElement.innerHTML + html;
    },

    loadMoreProductResults: function (results) {
        var searchResultListElement = document.querySelector('[data-search-result-list]');
        if (!searchResultListElement) {
            console.error('Monogram.SearchResults: no result list element');
            return;
        }
        var html = '';
        results.forEach(function (result) {
            html = html + this.productResultHtml(result);
        }.bind(this));
        searchResultListElement.innerHTML = searchResultListElement.innerHTML + html;

        Monogram.WishList.updateAllWishListItemIcons();
    },

    downloadArchiveResultHtml: function (result) {
        var searchResultTemplate = document.getElementById('archive-result-template');
        if (!searchResultTemplate) {
            console.error('Monogram.SearchResults: no search result template');
            return;
        }

        var archivedModels = result.archivedModels;
        var filename = result.title;
        var url = 'https://www.geappliances.com/search/older-pdfs/' + filename;

        var html = searchResultTemplate.innerHTML;
        html = html.replace(/\{archiveSkuList\}/g, encodeEntities(archivedModels));
        html = html.replace(/\{archiveUrl\}/g, encodeEntities(url));

        return html;
    },

    downloadResultHtml: function (result) {
        var searchResultTemplate = document.getElementById('search-result-template');
        if (!searchResultTemplate) {
            console.error('Monogram.SearchResults: no search result template');
            return;
        }

        var downloadFileTemplate = document.getElementById('download-item-template');
        if (!downloadFileTemplate) {
            console.error('Monogram.SearchResults: no download file template');
            return;
        }

        if (result.archivedModels && result.archivedModels.length) {
            return this.downloadArchiveResultHtml(result);
        }

        var t = "",
            title            = result.title || '',
            text             = result.text  || '',
            img              = result.image || false,
            link             = result.link  || '',
            documents        = result.documents,
            cadDocuments     = result.cadDocuments,
            lt               = "", // literature template
            desc             = title ? (title + ' - ' + text) : text,
            shortDescription = result.shortDescription,
            imgSrc,
            key,
            documentTitle,
            documentURL,
            className,
            temp;

        // NOTE: in the case of parts, both are true.
        var isProductSpec      = result.product;
        var isPartSpec         = result.part;

        if (typeof img === 'string' && (img.toLowerCase().indexOf("http") === 0 || img.indexOf("//") === 0)) {
            imgSrc = img;
        } else if (typeof img === 'string') {
            imgSrc = "https://products.geappliances.com/MarketingObjectRetrieval/Dispatcher?RequestType=Image&Name=" + img + "&Variant=SpecPage";
        } else {
            imgSrc = "https://www.geappliances.com/images/not_avail_spec.gif";
        }

        if (isProductSpec || isPartSpec) {
            // ON LITERATURE SEARCHES, we want to display the SKU as
            // the result title and any description as the result text
            // (yes, this is the opposite of what we're doing on
            // Product searches), but the JSON data has that the other
            // way around.
            temp = title; title = text; text = temp;
            // now `text` contains the SKU.
            if (!link) {
                if (isStaticEnvironment()) {
                    // you're looking at a template
                    link = "https://appliances.monogram.com/us/specs/" + encodeURIComponent(text);
                } else {
                    link = "/us/specs/" + encodeURIComponent(text);
                }
            }
        }

        if (shortDescription) {
            title = shortDescription;
        }

        var downloadItemsHtml = '';
        var downloadItemHtml;
        var downloadItemTitle;
        var downloadItemUrl;
        if (documents || cadDocuments) {
            for (key in documents) {
                if (documents.hasOwnProperty(key)) {
                    downloadItemHtml = downloadFileTemplate.innerHTML;
                    downloadItemTitle = key || documents[key];
                    downloadItemUrl = "https://products.geappliances.com/MarketingObjectRetrieval/Dispatcher?RequestType=PDF&Name=" + encodeURIComponent(documents[key]);
                    downloadItemHtml = downloadItemHtml.replace(/\{downloadItemTitle\}/g, encodeEntities(downloadItemTitle));
                    downloadItemHtml = downloadItemHtml.replace(/\{downloadItemUrl\}/g, encodeEntities(downloadItemUrl));
                    downloadItemsHtml += downloadItemHtml;
                }
            }
            for (key in cadDocuments) {
                if (cadDocuments.hasOwnProperty(key)) {
                    downloadItemHtml = downloadFileTemplate.innerHTML;
                    downloadItemTitle = key || documents[key];
                    downloadItemUrl = "https://products.geappliances.com/MarketingObjectRetrieval/Dispatcher?RequestType=Binary&Name=" + encodeURIComponent(cadDocuments[key]);
                    downloadItemHtml = downloadItemHtml.replace(/\{downloadItemTitle\}/g, encodeEntities(downloadItemTitle));
                    downloadItemHtml = downloadItemHtml.replace(/\{downloadItemUrl\}/g, encodeEntities(downloadItemUrl));
                    downloadItemsHtml += downloadItemHtml;
                }
            }
        }

        var absoluteLink = link;
        (function () {
            var a = document.createElement('a');
            a.href = link;
            absoluteLink = a.href;
        }());

        var html = searchResultTemplate.innerHTML;
        html = html.replace(/\{productUrl\}/g, encodeEntities(link));
        html = html.replace(/\{productAbsoluteUrl\}/g, encodeEntities(absoluteLink));
        html = html.replace(/\{productImage\}/g, encodeEntities(imgSrc));
        html = html.replace(/\{productSku\}/g, encodeEntities(text));
        html = html.replace(/\{productTitle\}/g, encodeEntities(title));
        html = html.replace(/\{downloadItems\}/g, downloadItemsHtml);
        return html;
    },

    pageResultHtml: function (result) {
        var searchResultTemplate = document.getElementById('search-result-template');
        if (!searchResultTemplate) {
            console.error('Monogram.SearchResults: no search result template');
            return;
        }

        var title = result.title || '',
            text  = result.text || '',
            link  = result.link || '',
            image = result.image;

        if (!image) {
            image = "https://api.url2png.com/v6/P54203BAAA68B5/3f3bfee5a8d32b81b1c1c945fdb0d097/png/?url=" + encodeURIComponent(link) + "&viewport=1050x1093&thumbnail_max_width=600";
        }

        // allow soft line breaks on mobile
        var linkDisplayed = encodeEntities(link.replace(/([^A-Za-z0-9])([A-Za-z0-9])/g, "$1\u200b$2"));

        var html = searchResultTemplate.innerHTML;
        html = html.replace(/\{pageUrl\}/g, encodeEntities(link));
        html = html.replace(/\{pageImage\}/g, encodeEntities(image));
        html = html.replace(/\{pageUrlDisplayed\}/g, linkDisplayed);
        html = html.replace(/\{pageDescription\}/g, encodeEntities(text));
        html = html.replace(/\{pageTitle\}/g, encodeEntities(title));
        return html;
    },

    productResultHtml: function (result) {
        var searchResultTemplate = document.getElementById('search-result-template');
        if (!searchResultTemplate) {
            console.error('Monogram.SearchResults: no search result template');
            return;
        }

        var title            = result.title || "",
            text             = result.text  || "",
            img              = result.image || false,
            link             = result.link || "",
            price,
            features         = result.features,
            isRecipe         = result.recipe,
            recipeTotalTime  = result.details && result.details["Total Time"],
            recipeServes     = result.details && result.details.Serves,
            partStatus       = result.partStatus,
            supersededSku    = result.supersededSku,
            availableStatus  = result.availableStatus,
            desc             = title ? (title + ' - ' + text) : text,
            shortDescription = result.shortDescription,
            imgSrc,
            temp,
            j;

        if (result.price === null) {
            price = null; // indicates item is no longer being manufactured.
        } else if (result.price) { // NOTE: sometimes the price is the string "0" (in which case we simply don't show the price.)
            price = Number(result.price);
        } else {
            price = ""; // simply don't show the price.
        }

        // NOTE: in the case of parts, both are true.
        var isProductSpec      = result.product;
        var isPartSpec         = result.part;

        var hasRecipeTotalTime = isRecipe && !empty(recipeTotalTime);
        var hasRecipeServes    = isRecipe && !empty(recipeServes);

        if (typeof img === 'string' && (img.toLowerCase().indexOf("http") === 0 || img.indexOf("//") === 0)) {
            imgSrc = img;
        } else if (typeof img === 'string') {
            if (isRecipe) {
                imgSrc = "http://genet.geappliances.com/Recipes/Dispatcher?REQUEST=ImageDownload&Site=GE&Variant=&MediaId=" + img;
            } else {
                imgSrc = "https://products.geappliances.com/MarketingObjectRetrieval/Dispatcher?RequestType=Image&Name=" + img + "&Variant=ViewLarger";
            }
        } else {
            if (isRecipe) {
                imgSrc = "https://www.geappliances.com/assets/images/recipes/recipe_nophoto_608x633.jpg";
            } else {
                imgSrc = "https://www.geappliances.com/images/not_avail_spec.gif";
            }
        }

        if (isProductSpec || isPartSpec) {
            // title contains the SKU and text contains
            // the name of the product.  But ON PRODUCT
            // SEARCHES, we want to display the product
            // name (text) as the result title, and the
            // SKU as the result text.  (Yes, this is the
            // opposite of what we're doing on Literature
            // searches.)
            temp = title;
            title = text;
            text = temp;

            // now `text` contains the SKU.

            if (!title) {
                title = text;
            }

            if (price && !link) {
                if (isStaticEnvironment()) {
                    // you're looking at a template
                    link = "https://appliances.monogram.com/us/specs/" + encodeURIComponent(text);
                } else {
                    link = "/us/specs/" + encodeURIComponent(text);
                }
            }
        }

        if (shortDescription) {
            title = shortDescription;
        }

        var descriptionHtml = '';
        if (availableStatus) {
            descriptionHtml += '<p>' + availableStatus + '</p>';
        }
        if (features && features.length) {
            features.forEach(function (feature) {
                feature = feature.replace(/^<li>(.*)<\/li>$/, '$1');
                descriptionHtml += '<p>' + feature + '</p>';
            });
        }

        var absoluteLink = link;
        (function () {
            var a = document.createElement('a');
            a.href = link;
            absoluteLink = a.href;
        }());

        var html = searchResultTemplate.innerHTML;
        html = html.replace(/\{productUrl\}/g, encodeEntities(link));
        html = html.replace(/\{productAbsoluteUrl\}/g, encodeEntities(absoluteLink));
        html = html.replace(/\{productImage\}/g, encodeEntities(imgSrc));
        html = html.replace(/\{productSku\}/g, encodeEntities(text));
        html = html.replace(/\{productTitle\}/g, encodeEntities(title));
        if (price) {
            html = html.replace(/\{productPrice\}/g, '$' + encodeEntities(price));
        } else {
            html = html.replace(/\{productPrice\}/g, '');
        }
        html = html.replace(/\{descriptionHtml\}/g, descriptionHtml);
        return html;
    },

    addEventListeners: function () {
        document.addEventListener('click', function (event) {
            var anchor = event.target;
            if (!anchor.hasAttribute('data-load-more-results')) {
                return;
            }
            var searchResultList = document.querySelector('[data-search-result-list]');
            if (!searchResultList) {
                console.error('Monogram.SearchResults: no [data-search-result-list] element found');
                return;
            }
            var resultType = this.getSearchResultType();
            if (!resultType) {
                console.error('Monogram.SearchResults: not a search results page, or invalid [data-page-type] on the <body>');
                return;
            }
            event.preventDefault();
            this.loadMoreResults();
        }.bind(this));
    },

    init: function () {
        this.initParams();
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.SearchResults.init();
});
