/*global Monogram, jQuery, console */

Monogram.PlayVideo = {
    openYouTubeVideo: function (videoId) {
        var $ = jQuery;

        var modal = document.getElementById('videoModal');
        var div;
        if (!modal) {
            div = document.createElement('div');
            document.body.appendChild(div);
            var html = '';
            html += '<div data-video-modal class="modal fade" id="videoModal" tabindex="-1" role="dialog">';
            html +=     '<div class="modal-dialog" role="document">';
            html +=         '<div class="modal-content">';
            html +=             '<div data-video-modal-body class="modal-body">';
            html +=             '</div>';
            html +=         '</div>';
            html +=     '</div>';
            html += '</div>';
            div.outerHTML = html;
            // add aria-labelledby="videoModalLabel" if you're adding a label
            modal = document.getElementById('videoModal');
            if (!modal) {
                return false;
            }
        }
        var modalBody = modal.querySelector('[data-video-modal-body]');
        if (!modalBody) {
            return false;
        }
        modalBody.innerHTML =
            '<div class="youtube-video">' +
            '<iframe class="youtube-video-iframe" src="https://www.youtube.com/embed/' + videoId + '?rel=0" frameborder="0" allowfullscreen></iframe>' +
            '</div>';
        $(modal).modal();
        return true;
    },
    closeYouTubeVideo: function () {
        var modal = document.getElementById('videoModal');
        if (!modal) {
            return false;
        }
        var modalBody = modal.querySelector('[data-video-modal-body]');
        if (!modalBody) {
            return false;
        }
        modalBody.innerHTML = '';
    },
    init: function () {
        var $ = jQuery;

        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('a[data-play-video]');
            if (!anchor) {
                return;
            }
            var videoId = anchor.getAttribute('data-youtube-video-id');
            if (videoId) {
                if (!this.openYouTubeVideo(videoId)) {
                    return;
                }
                event.preventDefault();
                return;
            }
        }.bind(this));
        $(document).on('hide.bs.modal', '#videoModal', function () {
            this.closeYouTubeVideo();
        }.bind(this));
    }
};

jQuery(function ($) {
    Monogram.PlayVideo.init();
}());
