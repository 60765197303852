/*global jQuery, Monogram */

Monogram.ProductCards = {
    init: function () {
        var $ = jQuery;

        $(document).on('show.bs.tab', '[data-toggle="tab"]', function () {
            Monogram.EqualHeight.updateEqualHeightCollections();
        });
        $(document).on('shown.bs.tab', '[data-toggle="tab"]', function () {
            Monogram.EqualHeight.updateEqualHeightCollections();
        });
        $(document).on('hide.bs.tab', '[data-toggle="tab"]', function () {
            Monogram.EqualHeight.updateEqualHeightCollections();
        });
        $(document).on('hidden.bs.tab', '[data-toggle="tab"]', function () {
            Monogram.EqualHeight.updateEqualHeightCollections();
        });
    }
};

jQuery(function ($) {
    Monogram.ProductCards.init();
});
