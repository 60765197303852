/*global Monogram, jQuery */

/**
 * Equal Height utility.
 *
 * Bootstrap's `row-eq-height` CSS class is fine for making column
 * heights equal, but it cannot equalize the heights of individual
 * elements within those columns.
 *
 * Usage:
 *
 *     <div ... data-equal-height [data-equal-height-by-row="true"]>
 *         ...
 *             <div data-equal-height-watch>
 *                 ...
 *             </div>
 *         ...
 *             <div data-equal-height-watch>
 *                 ...
 *             </div>
 *         ...
 *     </div>
 *
 * Multiple sets of equal height blocks within a [data-equal-height] div...
 *
 *     <div ... data-equal-height [data-equal-height-by-row="true"]>
 *         ...
 *             <div data-equal-height-watch="A">
 *                 ...
 *             </div>
 *         ...
 *             <div data-equal-height-watch="B">
 *                 ...
 *             </div>
 *         ...
 *             <div data-equal-height-watch="A">
 *                 ...
 *             </div>
 *         ...
 *             <div data-equal-height-watch="B">
 *                 ...
 *             </div>
 *         ...
 *     </div>
 */

Monogram.EqualHeight = {
    /**
     * Update any equal height collections within the specified element.
     * The document is used if element is not specified.
     * If the element itself is a [data-equal-height], updates that element.
     */
    updateEqualHeightCollections: function (element) {
        if (element) {
            if (element.hasAttribute && element.hasAttribute('[data-equal-height]')) {
                window.requestAnimationFrame(function () {
                    this.updateEqualHeightCollection(element);
                }.bind(this));
            } else {
                window.requestAnimationFrame(function () {
                    element.querySelectorAll('[data-equal-height]').forEach(function (collection) {
                        this.updateEqualHeightCollection(collection);
                    }.bind(this));
                }.bind(this));
            }
        } else {
            window.requestAnimationFrame(function () {
                document.querySelectorAll('[data-equal-height]').forEach(function (collection) {
                    this.updateEqualHeightCollection(collection);
                }.bind(this));
            }.bind(this));
        }
    },

    updateEqualHeightCollection: function (collection) {
        /**
         * Allow calls to this function on a descendent of a
         * [data-equal-height] element.  Such as a checkbox in
         * a product card.
         */
        if (!collection) {
            return;
        }
        if (!collection.hasAttribute('data-equal-height')) {
            collection = collection.closest('[data-equal-height]');
            if (!collection) {
                return;
            }
        }
        var byRow = JSON.parse(collection.getAttribute('data-equal-height-by-row'));
        var targets = Array.from(collection.querySelectorAll('[data-equal-height-watch]'));
        var groupNames = this.groupNames(collection);
        groupNames.forEach(function (groupName) {
            var groupTargets = targets.filter(function (target) {
                return target.getAttribute('data-equal-height-watch') === groupName;
            });
            this.equalizeGroup(targets, byRow);
        }.bind(this));
    },

    equalizeGroup: function (targets, byRow) {
        var maxHeight;
        var minOffsetTop;
        var topTargets;
        var otherTargets;
        var remainingTargets;

        var setMaxHeight = function setMaxheight(target) {
            target.style.height = maxHeight + 'px';
        };
        var unsetMaxHeight = function unsetMaxHeight(target) {
            target.style.height = 'auto';
        };

        if (byRow) {
            targets.forEach(unsetMaxHeight);
            remainingTargets = targets.slice(0);
            while (remainingTargets.length) {
                minOffsetTop = this.minOffsetTop(remainingTargets);
                topTargets = this.havingOffsetTop(remainingTargets, minOffsetTop);
                if (topTargets.length < 1) {
                    break;
                }
                remainingTargets = this.havingOffsetTop(remainingTargets, minOffsetTop, false);
                maxHeight = this.maxHeight(topTargets);
                topTargets.forEach(setMaxHeight);
            }
            targets.forEach(function (target) {
                delete target.dataset.ehTop;
            });
        } else {
            targets.forEach(unsetMaxHeight);
            maxHeight = this.maxHeight(targets);
            targets.forEach(setMaxHeight);
        }
    },

    maxHeight: function (elements) {
        var result;
        elements.forEach(function (element) {
            var height = element.offsetHeight;
            if (result === undefined) {
                result = element.offsetHeight;
            } else {
                result = Math.max(result, element.offsetHeight);
            }
        });
        return result;
    },

    minOffsetTop: function (elements) {
        var result;
        elements.forEach(function (element) {
            var rect = element.getBoundingClientRect();
            var top = rect.top;
            element.dataset.ehTop = top;
            if (result === undefined) {
                result = top;
            } else {
                result = Math.min(result, top);
            }
        });
        return result;
    },

    havingOffsetTop: function (elements, top, negate) {
        return elements.filter(function (element) {
            var result = (Number(element.dataset.ehTop) === top);
            if (negate === false) {
                result = !result;
            }
            return result;
        });
    },

    /**
     * Elements within a data-equal-height can have data-equal-height-watch
     * attributes other than the empty string.  If this is done, this
     * function returns them in the order in which they are first
     * specified in the collection element.
     */
    groupNames: function (collection) {
        var result = [];
        var exists = {};
        var targets = Array.from(collection.querySelectorAll('[data-equal-height-watch]'));
        targets.forEach(function (target) {
            var group = target.getAttribute('data-equal-height-watch');
            if (!(group in exists && exists.hasOwnProperty(group))) {
                result.push(group);
                exists[group] = true;
            }
        }.bind(this));
        return result;
    },

    init: function () {
        window.addEventListener('resize', function () {
            this.updateEqualHeightCollections();
        }.bind(this));

        // once right away
        this.updateEqualHeightCollections();

        // and once when all resources (including fonts, images, etc.)
        // are loaded
        window.addEventListener('load', function () {
            this.updateEqualHeightCollections();
        }.bind(this));
    }
};

jQuery(function ($) {
    Monogram.EqualHeight.init();
});
