/*global jQuery, Monogram, console */

Monogram.HomePage = {

    getSectionIndex: function (indices, index) {
        var result;
        var i;
        for (i = 0; i < indices.length; i += 1) {
            if (index >= indices[i]) {
                result = i;
            }
        }
        return result;
    },

    changeActiveSection: function (wrapper) {
        if (!('sliderIndices' in wrapper.dataset) ||
            !('sectionNames' in wrapper.dataset) ||
            !('slideIndex' in wrapper.dataset)) {
            return;
        }
        var sliderIndices = JSON.parse(wrapper.dataset.sliderIndices);
        var sectionNames  = JSON.parse(wrapper.dataset.sectionNames);
        var slideIndex    = JSON.parse(wrapper.dataset.slideIndex);
        var sectionIndex  = this.getSectionIndex(sliderIndices, slideIndex);

        wrapper.querySelectorAll('[data-highlight-slider-active-name]').forEach(function (element) {
            element.innerHTML = sectionNames[sectionIndex];
        }.bind(this));

        wrapper.querySelectorAll('[data-highlight-slider-nav="tab"]').forEach(function (element) {
            Array.from(element.children).forEach(function (li, index) {
                if (index === sectionIndex) {
                    li.classList.add('active');
                } else {
                    li.classList.remove('active');
                }
            });
        }.bind(this));
    },

    goToSlide: function (wrapper, slideIndex) {
        var $ = jQuery;
        $(wrapper).find('.highlight-slider').slick('slickGoTo', slideIndex);
        wrapper.dataset.slideIndex = slideIndex;
        this.changeActiveSection(wrapper);
    },

    initHighlightSlider: function () {
        var $ = jQuery;

        document.querySelectorAll('[data-highlight-slider-wrapper]').forEach(function (wrapper) {
            var slideIndex = 0;
            var sliderIndices = [];
            var sectionNames = [];
            var slider = wrapper.querySelector('[data-highlight-slider]');
            if (!slider) {
                return;
            }
            slider.querySelectorAll('[data-highlight-slider-section]').forEach(function (section) {
                var sectionIndex = slideIndex;
                sliderIndices.push(sectionIndex);
                sectionNames.push(section.getAttribute('data-highlight-slider-section'));
                section.querySelectorAll('[data-highlight-slider-item]').forEach(function (item) {
                    slider.appendChild(item);
                    slideIndex += 1;
                }.bind(this));
                section.parentNode.removeChild(section);
            }.bind(this));
            wrapper.querySelectorAll('[data-highlight-slider-nav]').forEach(function (nav) {
                nav.querySelectorAll('[data-highlight-slider-link]').forEach(function (link, sectionIndex) {
                    link.setAttribute('data-highlight-slider-link', sliderIndices[sectionIndex]);
                });
            }.bind(this));
            wrapper.dataset.sliderIndices = JSON.stringify(sliderIndices);
            wrapper.dataset.sectionNames = JSON.stringify(sectionNames);
            wrapper.dataset.slideIndex = 0;
        }.bind(this));

        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-highlight-slider-link]');
            if (!anchor) {
                return;
            }
            var slideIndex = parseInt(anchor.getAttribute('data-highlight-slider-link'), 10);
            if (isNaN(slideIndex) || slideIndex < 0) {
                return;
            }
            var wrapper = anchor.closest('[data-highlight-slider-wrapper]');
            if (!wrapper) {
                return;
            }
            this.goToSlide(wrapper, slideIndex);
        }.bind(this));

        $('.highlight-slider').slick({
            infinite: false,
            mobileFirst: true,
            swipeToSlide: true,
            slidesToShow: 1,
            variableWidth: true,
            arrows: true
        });
        $(document).on('beforeChange', '.highlight-slider', function (event, slick, currentSlide, nextSlide) {
            slick = event.target;
            if (!slick) {
                return;
            }
            var wrapper = slick.closest('[data-highlight-slider-wrapper]');
            if (!wrapper) {
                return;
            }
            wrapper.dataset.slideIndex = JSON.stringify(nextSlide);
            this.changeActiveSection(wrapper);
        }.bind(this));
    },

    init: function () {
        if (!document.body.hasAttribute('data-home-page')) {
            return;
        }
        this.initHighlightSlider();
    }
};

jQuery(function ($) {
    Monogram.HomePage.init();
});
