/**
 * Monogram Event Calendar code.
 *
 * Used by:
 * - Design center pages (Chicago, Denver, etc.)
 * - Event registration and thanks pages
 * - Event calendar page
 */

/*global URLSearchParams, timezoneJS, hyperform, jQuery, UNICODE, encodeEntities, decodeEntitiesStripHTML */
/*global isStaticTestingEnvironment, isStaticEnvironment */
/*jshint devel: true */

function EventCalendar () {
    this.events                    = [];
    this.eventById                 = {};
    this.locationData              = {};
    this.selectedLocationName      = null;
    this.regionNames               = [];
    this.regionData                = {};
    this.locationNameToRegionNames = {};

    this.params = new URLSearchParams(location.search);

    var now = new Date();

    /*
     * Timestamp used to filter out past events.
     */
    this.eventFilterBeforeTime = now.getTime();

    /*
     * Initially selected year and month for navigation.  Does not
     * change as the user navigates.  We store this so the user does
     * not navigate to a previous month.
     */
    this.currentYear  = now.getFullYear();
    this.currentMonth = now.getMonth(); // 0 to 11

    /*
     * Currently selected year and month for navigation.  Changes as
     * the user navigates.
     */
    this.state = { year: this.currentYear, month: this.currentMonth };
}

/**
 * NOTES REGARDING TIMEZONE.JS
 *
 * (1) We are using pre-parsed JSON data to keep page loading times as low as
 * we can.
 *
 * (2) If you update date.js, you must regenerate the pre-parsed JSON data
 * using the version of node-preparse.js or preparse.js that comes along.
 *
 * (3) If we for some reason need to add another time zone, you'll need to
 * regenerate the JSON as well.
 *
 * (4) Don't call timezoneJS.timezone.init() when loading from pre-parsed JSON
 * data.
 *
 * timezoneJS.Date is available here:
 *
 *     https://github.com/mde/timezone-js
 *
 * The timezone data is located at:
 *
 *     http://www.iana.org/time-zones/
 */

EventCalendar.loadTimeZoneData = function (callback) {
    //
    // TODO: make this work asynchronously.  loadZoneJSONData does not
    // apparently support passing a callback though.
    //
    // Says Google Chrome's developer console when loading the tzdata:
    //
    //     Synchronous XMLHttpRequest on the main thread is deprecated
    //     because of its detrimental effects to the end user's
    //     experience. For more help, check
    //     https://xhr.spec.whatwg.org/
    //
    var tz = timezoneJS.timezone;
    tz.loadingScheme = tz.loadingSchemes.MANUAL_LOAD;
    var url;
    if (isStaticEnvironment()) {
        // we're viewing a development template
        url = '/assets2/js/events/timezones.json';
    } else {
        // assume we're in the application
        url = '/us/json/timezones.json';
    }
    tz.loadZoneJSONData(url, true /* synchronous */);
    if (callback) {
        callback();
    }
};

EventCalendar.DAYS = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

EventCalendar.ABBR_DAYS = [
    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

EventCalendar.MONTHS = [
    // 0 to 11
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

EventCalendar.ABBR_MONTHS = [
    // 0 to 11
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

EventCalendar.prototype.addLocation = function (locationName, data) {
    this.locationData[locationName] = data;
};

EventCalendar.prototype.addRegion = function (regionName, data) {
    this.regionNames.push(regionName);
    this.regionData[regionName] = data;
    this.regionData[regionName].events = [];
    this.regionData[regionName].name = regionName;
    data.locationNames.forEach(function (locationName) {
        if (!(locationName in this.locationNameToRegionNames)) {
            this.locationNameToRegionNames[locationName] = [];
        }
        this.locationNameToRegionNames[locationName].push(regionName);
    }, this);
};

EventCalendar.prototype.add = function (event) {
    this.events.push(event);
};

// Executed by event_calendar_data.js after all data is populated into
// the object.
EventCalendar.prototype.finalize = function () {
    var errors = [];

    // Finalize events and do some sanity checking.
    this.events.forEach(function (event) {
        this.finalizeEvent(event);
        if (event.id in this.eventById) {
            errors.push("WARNING: You have multiple events with id '" + event.id + "'.");
        } else {
            this.eventById[event.id] = event;
        }
    }, this);

    // Sort events.
    this.events.sort(function (a, b) {
        return a.startDateTime - b.startDateTime;
    });

    // On production, and on testing application environments, filter
    // test events out.  When using Teamsite, leave them in UNLESS
    // noTestEvents=1 is in the query string.
    if (this.params.get("noTestEvents") || !isStaticTestingEnvironment()) {
        this.events = this.events.filter(function (event) {
            return !event.isTestEvent;
        }, this);
    }

    this.eventsByLocation = {};

    this.earliestYearAndMonthByLocation = {};
    this.latestYearAndMonthByLocation   = {};
    this.earliestYearAndMonth = null;
    this.latestYearAndMonth   = null;

    Object.keys(this.locationData).forEach(function (locationName) {
        var locationData = this.locationData[locationName];
        var timezone = locationData.timezone;

        if (!timezone) {
            throw new Error("this.locationData does not contain a timezone property for '" + locationName + "'.");
        }

        var eventsByLocation = this.events.filter(function (event) {
            return event.locationName === locationName;
        }, this);

        /*
         * The event filter time, normalized to the first of the month
         * at noon.  We use this timestamp to filter out events from
         * previous MONTHS, but still show events going back to the
         * first of THIS month.
         */
        var noonTheFirstOfTheMonth = new timezoneJS.Date(this.eventFilterBeforeTime, timezone);
        noonTheFirstOfTheMonth.setDate(1);
        noonTheFirstOfTheMonth.setHours(12);
        noonTheFirstOfTheMonth.setMinutes(0);
        noonTheFirstOfTheMonth.setSeconds(0);
        noonTheFirstOfTheMonth.setMilliseconds(0);

        /*
         * Flag on events used for filtering.  We set these flags
         * first, then filter them out of each location's event list
         * later, so that we can filter out the events array on the
         * object (events for all locations) as well.
         */
        eventsByLocation.forEach(function (event) {
            event.monthHasPassed = false;
            if (event.noonThatDay.getTime() < noonTheFirstOfTheMonth.getTime()) {
                event.monthHasPassed = true;
            }
        }, this);

        // Filter out previous events by location.
        eventsByLocation = eventsByLocation.filter(function (event) {
            return !event.monthHasPassed;
        }, this);

        eventsByLocation.forEach(function (event) {
            var yearAndMonth;

            // Collect events by region.
            if (this.locationNameToRegionNames[locationName]) {
                this.locationNameToRegionNames[locationName].forEach(function (regionName) {
                    this.regionData[regionName].events.push(event);
                }, this);
            }

            // Get earliest and latest months for which there are
            // events by location name.
            yearAndMonth = { year: event.yearNumber, month: event.monthNumber };
            if (!this.earliestYearAndMonthByLocation[locationName]) {
                this.earliestYearAndMonthByLocation[locationName] = yearAndMonth;
            }
            this.latestYearAndMonthByLocation[locationName] = yearAndMonth;
        }, this);

        this.eventsByLocation[locationName] = eventsByLocation;
    }, this);

    // Filter out previous events.
    this.events = this.events.filter(function (event) {
        return !event.monthHasPassed;
    }, this);

    // Get earliest and latest months for which there are events, for
    // the whole event calendar.
    this.events.forEach(function (event) {
        var yearAndMonth = { year: event.yearNumber, month: event.monthNumber };
        if (!this.earliestYearAndMonth) {
            this.earliestYearAndMonth = yearAndMonth;
        }
        this.latestYearAndMonth = yearAndMonth;
    }, this);

    if (isStaticTestingEnvironment() && errors.length) {
        alert(errors.join("\n"));
    }
};

// Called by finalize method on each event.
EventCalendar.prototype.finalizeEvent = function (event) {
    var yyyy, mm, dd, year, month, day;
    var startHour, startMinute, endHour, endMinute;

    var timezone = this.locationData[event.locationName].timezone;
    event.locationData = this.locationData[event.locationName];

    event.id = String(event.id); // coerce into string
    if (/^\s*(\d\d\d\d)\s*-\s*(\d\d?)\s*-\s*(\d\d?)\s*$/.test(event.date)) {
        yyyy  = RegExp.$1;
        mm    = RegExp.$2;
        dd    = RegExp.$3;
        year  = parseInt(yyyy, 10);
        month = parseInt(mm, 10) - 1; // need 0 to 11
        day   = parseInt(dd, 10);
    }
    if (/^\s*(\d\d?)\s*:\s*(\d\d)\s*$/.test(event.startTime)) {
        startHour   = RegExp.$1;
        startMinute = RegExp.$2;
        startHour   = parseInt(startHour, 10);
        startMinute = parseInt(startMinute, 10);
    }
    if (/^\s*(\d\d?)\s*:\s*(\d\d)\s*$/.test(event.endTime)) {
        endHour   = RegExp.$1;
        endMinute = RegExp.$2;
        endHour   = parseInt(endHour, 10);
        endMinute = parseInt(endMinute, 10);
    }

    event.noonThatDay   = new timezoneJS.Date(year, month, day, 12,        0,           0, 0, timezone);
    event.startDateTime = new timezoneJS.Date(year, month, day, startHour, startMinute, 0, 0, timezone);
    event.endDateTime   = new timezoneJS.Date(year, month, day, endHour,   endMinute,   0, 0, timezone);

    // we want the same time of day three days before the event's date.
    (function () {
        event.threeDaysBeforeStart = new timezoneJS.Date(event.startDateTime.getTime(), timezone);

        // set the time to 12:00
        event.threeDaysBeforeStart.setHours(12);
        event.threeDaysBeforeStart.setMinutes(0);
        event.threeDaysBeforeStart.setSeconds(0);
        event.threeDaysBeforeStart.setMilliseconds(0);

        // subtract 72 hours; across DST boundary might be 11:00 or 13:00 but that's okay...
        event.threeDaysBeforeStart.setTime(event.threeDaysBeforeStart.getTime() - 1000 * 86400 * 3);

        // ...because we'll get the same time of day this way.
        event.threeDaysBeforeStart.setHours(event.startDateTime.getHours());
        event.threeDaysBeforeStart.setMinutes(event.startDateTime.getMinutes());
        event.threeDaysBeforeStart.setSeconds(event.startDateTime.getSeconds());
        event.threeDaysBeforeStart.setMilliseconds(event.startDateTime.getMilliseconds());
    }());

    event.yearNumber  = year;
    event.monthNumber = month;  // 0 to 11

    event.month                    = this.formatMonth(event.startDateTime);
    event.formattedDate            = this.formatDate(event.startDateTime);
    event.formattedDateWithoutYear = this.formatDateWithoutYear(event.startDateTime); // for footer
    event.formattedTimeRange       = this.formatTimeRange(event.startDateTime, event.endDateTime);
    event.formattedTimeRange2      = this.formatTimeRange(event.startDateTime, event.endDateTime, { asciiOnly: true }); // for email subject
    event.shortlyFormattedDate     = mm + "/" + dd + "/" + yyyy;

    if (event.linkType === "register") {
        if (!event.linkURL) {
            event.linkURL = "/events/register.htm";
            event.linkURL += "?id=" + encodeURIComponent(event.id);
        }
    } else if (event.linkType === "register-ceu") {
        if (!event.linkURL) {
            event.linkURL = "/events/register-ceu.htm";
            event.linkURL += "?id=" + encodeURIComponent(event.id);
        }
    }
    // assume event.linkURL is defined if neither "register" nor
    // "register-ceu" is specified for event.linkType.

    if (!isStaticEnvironment()) {
        // at this point, assume we're on an application page
        if (event.linkURL) {
            if (!/^https?:\/\//.test(event.linkURL)) {
                // assume relative links, either specified as
                // event.linkURL or defaulted as above, are relative
                // to monogram.com web site.
                event.linkURL = "https://www.monogram.com" + event.linkURL;
            }
        }
    }

    if (!event.linkText) {
        event.linkText = "Register";
    }
    if (!event.linkTarget) {
        event.linkTarget = "_self";
    }

    event.hasPassed             = event.startDateTime      <= this.eventFilterBeforeTime;
    event.isLessThanThreeDaysAway = event.threeDaysBeforeStart <= this.eventFilterBeforeTime;

    event.isClosed = event.registrationIsFull || event.isLessThanThreeDaysAway;
};

// Returns a string like "Wednesday, Jul 9, 2008".
EventCalendar.prototype.formatDate = function (date) {
    return EventCalendar.DAYS[date.getDay()] +
        ", " + EventCalendar.ABBR_MONTHS[date.getMonth()] +
        " " + date.getDate() + ", " + date.getFullYear();
};

// Returns a string like "Jul 9, 2008".
EventCalendar.prototype.formatDateShort = function (date) {
    return EventCalendar.ABBR_MONTHS[date.getMonth()] +
        " " + date.getDate() + ", " + date.getFullYear();
};

// Returns a string like "July 9".
EventCalendar.prototype.formatDateWithoutYear = function (date) {
    return EventCalendar.MONTHS[date.getMonth()] + " " + date.getDate();
};

// Returns a string like "July 2008".
EventCalendar.prototype.formatMonth = function (date) {
    return EventCalendar.MONTHS[date.getMonth()] + " " + date.getFullYear();
};

// Returns a string like "Noon" or "Midnight" or "1:30 p.m.".  Time
// zone not included.
EventCalendar.prototype.formatTime = function (date) {
    var h, m, hh, mm, ap;
    h  = date.getHours();
    m  = date.getMinutes();
    if (h === 0 && m === 0) {
        return "Midnight";
    }
    if (h === 12 && m === 0) {
        return "Noon";
    }
    hh = ((h + 11) % 12 + 1).toString();
    mm = m.toString();
    while (mm.length < 2) {
        mm = "0" + mm;
    }
    ap = (h < 12) ? "a" : "p";
    return hh + ":" + mm + ap;
};

// Returns a string like "Noon - 3:30 p.m. CDT".
EventCalendar.prototype.formatTimeRange = function (startDateTime, endDateTime, /* optional */ options) {
    var resultStart, resultEnd, tzStart, tzEnd, result, enDash;

    resultStart = this.formatTime(startDateTime);
    resultEnd   = this.formatTime(endDateTime);
    tzStart     = startDateTime.getTimezoneAbbreviation();
    tzEnd       = endDateTime.getTimezoneAbbreviation();

    enDash = UNICODE.EN_DASH;
    if (options && options.asciiOnly) {
        enDash = "to";
    }

    if (tzStart && tzEnd) {
        if (tzStart === tzEnd) {
            result = resultStart + " " + enDash + " " + resultEnd + " " + tzStart;
        } else {
            result = resultStart + " " + tzStart + " " + enDash + " " + resultEnd + " " + tzEnd;
        }
    } else {
        // This should never happen.
        result =  resultStart + " " + enDash + " " + resultEnd;
    }

    return result;
};

EventCalendar.prototype.eventCalendarHTML = function (year, month /* 0 to 11 */) {
    var events, html = '', $ = jQuery;

    // for testing
    var showNoEventsMessage = this.params.get("show-no-events-message");

    if (!year && !month) {
        year = this.currentYear;
        month = this.currentMonth;
    }
    if (this.selectedLocationName) {
        events = this.getEventsByYearAndMonthAndLocationName(year, month, this.selectedLocationName);
    } else {
        events = this.getEventsByYearAndMonth(year, month);
    }

    if (showNoEventsMessage || !events.length) {
        html += '<div class="event-calendar-empty-message">';
        html +=     'No events are scheduled for this month yet. Check back for updates.';
        html += '</div>';
    } else {
        html += '<div class="event-calendar">';
        events.forEach(function (event) {
            html += this.eventHTML(event);
        }, this);
        html += '</div>';
    }

    return html;
};

EventCalendar.prototype.eventHTML = function (event, options) {
    var html = '';
    var classNames = 'event';
    var target;

    var single = options && options.single;
    var noRegister = options && options.noRegister;

    if (event.constructor === String) {
        event = this.eventById[event];
    }

    if (single) {
        classNames += ' event-single';
    }

    if (event.isClosed) {
        classNames += ' event-closed';
    }

    if (event.isLessThanThreeDaysAway) {
        classNames += ' event-soon';
    }
    if (event.hasPassed) {
        classNames += ' event-passed';
    }

    target = event.locationData.viewMap.target;
    if (!target) {
        target = "_self";       // the default
    }

    html += '<div class="' + classNames + '">';

    html +=     '<div class="event-title-column">';
    html +=         '<div class="event-title">' + event.title + '</div>';
    html +=         '<div class="event-description">' + event.description + '</div>';
    html +=     '</div>';

    html +=     '<div class="event-info-column">';
    html +=         '<div class="event-city">' + event.locationData.cityName.htmlEntities() + '</div>';
    html +=         '<div class="event-date">' + event.formattedDate.htmlEntities() + '</div>';
    html +=         '<div class="event-time">' + event.formattedTimeRange.htmlEntities() + '</div>';
    html +=         '<div class="event-address">' + event.locationData.address.join("\n").htmlEntities({ "<br>": true }) + '</div>';
    html +=         '<div class="event-view-map"><a href="' + event.locationData.viewMap.url.htmlEntities() + '" target="' + target.htmlEntities() + '">View Map</a></div>';
    html +=     '</div>';

    var reason;

    if (!noRegister && event.linkURL) {
        if (event.hasPassed) {
            reason = 'This event has already started or is over.';
        } else if (event.isLessThanThreeDaysAway) {
            reason = 'Due to the prep work required for events, registration will close 3 days before a scheduled event.';
        } else if (event.registrationIsFull) {
            reason = 'Registration for this event is full.  Click to be placed on the waitlist.  If another session is created, you will be notified by email or phone.';
        }

        var trackEventName = decodeEntitiesStripHTML(event.title);
        var trackEventLocation = this.locationData[event.locationName].cityName;
        var trackEventDate = this.formatDateShort(event.startDateTime);

        html +=     '<div class="event-register-column">';
        if (event.hasPassed) {
            html +=     '<div class="event-register event-register-done">';
            html +=         '<span title="' + reason + '" class="btn btn-light-gray disabled btn-event btn-event-done">Registration Closed</span>';
            html += '</div>';
        } else if (event.isClosed) {
            html +=     '<div class="event-register event-register-closed">';
            html +=         '<a title="' + reason + '" class="btn btn-light-gray btn-event btn-event-closed"';
            html +=         ' data-mg-track="events-interest"';
            html +=         ' data-mg-track-event-name="'     + encodeEntities(trackEventName)     + '"';
            html +=         ' data-mg-track-event-location="' + encodeEntities(trackEventLocation) + '"';
            html +=         ' data-mg-track-event-date="'     + encodeEntities(trackEventDate)     + '"';
            html +=         ' target="' + event.linkTarget.htmlEntities() + '"';
            html +=         ' href="' + event.linkURL.htmlEntities() + '">Registration Closed</a></div>';
        } else {
            html +=     '<div class="event-register">';
            html +=         '<a class="btn btn-default btn-event"';
            html +=         ' data-mg-track="events-interest"';
            html +=         ' data-mg-track-event-name="'     + encodeEntities(trackEventName)     + '"';
            html +=         ' data-mg-track-event-location="' + encodeEntities(trackEventLocation) + '"';
            html +=         ' data-mg-track-event-date="'     + encodeEntities(trackEventDate)     + '"';
            html +=         ' target="' + event.linkTarget.htmlEntities() + '"';
            html +=         ' href="' + event.linkURL.htmlEntities() + '">';
            html +=             event.linkText.htmlEntities() + '</a></div>';
        }
        html +=     '</div>';   // ends .event-register-column
    }

    html += '</div>';           // ends .event

    return html;
};

EventCalendar.prototype.singleEventHTML = function (event) {
    var html = '';
    var classNames = 'event event-single';

    if (event.constructor === String) {
        event = this.eventById[event];
    }

    if (event.isClosed) {
        classNames += ' event-closed';
    }

    if (event.isLessThanThreeDaysAway) {
        classNames += ' event-soon';
    }
    if (event.hasPassed) {
        classNames += ' event-passed';
    }

    html += '<div class="' + classNames + '">';
    html +=     '<div class="event-title">' + event.title + '</div>';
    html +=     '<div class="event-date">' + event.formattedDate.htmlEntities() + '</div>';
    html +=     '<div class="event-time">' + event.formattedTimeRange.htmlEntities() + '</div>';
    html +=     '<div class="event-address">' + event.locationData.address.join("\n").htmlEntities({ "<br>": true }) + '</div>';
    html +=     '<div class="event-view-map"><a href="' + event.locationData.viewMap.url.htmlEntities() + '" target="' + event.locationData.viewMap.target.htmlEntities() + '">View Map</a></div>';
    html +=     '<div class="event-description">' + event.description + '</div>';
    html += '</div>';

    return html;
};

// NO LONGER IN USE
EventCalendar.prototype.singleEventFooterHTML = function (event, options) {
    var html = '';

    if (event.constructor === String) {
        event = this.eventById[event];
    }

    if (event.linkURL) {
        html += '<a target="' + event.linkTarget.htmlEntities() + '" href="' + event.linkURL.htmlEntities() + '" class="footer-event anchor-footer-event">';
    } else {
        html += '<span class="footer-event">';
    }

    html +=     '<p class="event-location">' + event.locationData.cityName.htmlEntities() + '</p>';
    html +=     '<p class="event-title">' + event.title + '</p>';
    html +=     '<p class="event-time">';
    html +=         '<span class="event-time-date">' + event.formattedDateWithoutYear.htmlEntities() + ',</span> ';
    html +=         '<span class="event-time-time">' + event.formattedTimeRange.htmlEntities() + '</span>';
    html +=     '</p>';

    if (event.linkURL) {
        html += '</a>';
    } else {
        html += '</span>';
    }

    return html;
};

EventCalendar.prototype.getEventsByYearAndMonth = function (year, month) {
    var result = [];
    if (!year && !month) {
        year = this.currentYear;
        month = this.currentMonth;
    }
    this.events.forEach(function (event) {
        if (event.yearNumber === year && event.monthNumber === month) {
            result.push(event);
        }
    }, this);
    return result;
};

EventCalendar.prototype.getEventsByYearAndMonthAndLocationName = function (year, month, locationName) {
    var result = [];
    if (!year && !month) {
        year = this.currentYear;
        month = this.currentMonth;
    }
    this.events.forEach(function (event) {
        if (event.yearNumber === year && event.monthNumber === month &&
            event.locationName === locationName) {
            result.push(event);
        }
    }, this);
    return result;
};

EventCalendar.prototype.isThisMonthOrEarlier = function (year, month) {
    if (year === this.currentYear && month === this.currentMonth) {
        return true;
    }
    if (year < this.currentYear || year === this.currentYear && month < this.currentMonth) {
        return true;
    }
    return false;
};

EventCalendar.prototype.isEarliestMonthOrEarlier = function (year, month) {
    var earliest;
    var earliestYear;
    var earliestMonth;
    if (this.selectedLocationName) {
        earliest = this.earliestYearAndMonthByLocation[this.selectedLocationName];
        if (earliest) {
            earliestYear  = earliest.year;
            earliestMonth = earliest.month;
        } else {
            // No events at all.  Disable navigation.
            return true;
        }
    } else {
        earliest = this.earliestYearAndMonth;
        if (earliest) {
            earliestYear  = earliest.year;
            earliestMonth = earliest.month;
        } else {
            // No events at all.  Disable navigation.
            return true;
        }
    }
    if (year === earliestYear && month === earliestMonth) {
        return true;
    }
    if (year < earliestYear || year === earliestYear && month < earliestMonth) {
        return true;
    }
    return false;
};

EventCalendar.prototype.isLatestMonthOrLater = function (year, month) {
    var latest;
    var latestYear;
    var latestMonth;
    if (this.selectedLocationName) {
        latest = this.latestYearAndMonthByLocation[this.selectedLocationName];
        if (latest) {
            latestYear  = latest.year;
            latestMonth = latest.month;
        } else {
            // No events at all.  Disable navigation.
            return true;
        }
    } else {
        latest = this.latestYearAndMonth;
        if (latest) {
            latestYear  = latest.year;
            latestMonth = latest.month;
        } else {
            // No events at all.  Disable navigation.
            return true;
        }
    }
    if (year === latestYear && month === latestMonth) {
        return true;
    }
    if (year > latestYear || year === latestYear && month > latestMonth) {
        return true;
    }
    return false;
};

EventCalendar.prototype.hasEventsAtAll = function () {
    if (this.selectedLocationName) {
        return !!(this.earliestYearAndMonthByLocation[this.selectedLocationName]);
    } else {
        return !!(this.earliestYearAndMonth);
    }
};

/**
 * Called initially and when changing the month.
 */
EventCalendar.prototype.populateMonthChooser = function () {
    var $ = jQuery;

    var stateYear = this.state.year;
    var stateMonth = this.state.month;

    var showPreviousArrow = true;
    var showNextArrow = true;

    if (this.hasEventsAtAll()) {
        if (this.isThisMonthOrEarlier(stateYear, stateMonth)) {
            showPreviousArrow = false;
        }
        if (this.isLatestMonthOrLater(stateYear, stateMonth)) {
            showNextArrow = false;
        }
    } else {
        showPreviousArrow = false;
        showNextArrow = false;
    }

    $("[data-event-calendar-month-chooser]").empty();
    if (showPreviousArrow) {
        $("[data-event-calendar-month-chooser]").append("<span class='event-calendar-arrow-wrapper'><a class='event-calendar-previous-month-link'><i class='fas fa-chevron-left'></i></a></span>");
    } else {
        $("[data-event-calendar-month-chooser]").append("<span class='event-calendar-arrow-wrapper disabled'><i class='fas fa-chevron-left'></i></span>");
    }
    $("[data-event-calendar-month-chooser]").append("<span class='event-calendar-month-display'>" + EventCalendar.MONTHS[stateMonth] + "</span>");
    if (showNextArrow) {
        $("[data-event-calendar-month-chooser]").append("<span class='event-calendar-arrow-wrapper'><a class='event-calendar-next-month-link'><i class='fas fa-chevron-right'></i></a></span>");
    } else {
        $("[data-event-calendar-month-chooser]").append("<span class='event-calendar-arrow-wrapper disabled'><i class='fas fa-chevron-right'></i></span>");
    }

    var that = this;
    $("a.event-calendar-previous-month-link").unbind("click").click(function () {
        that.goToPreviousMonth();
        return false;           // prevent browser default action
    });
    $("a.event-calendar-next-month-link").unbind("click").click(function () {
        that.goToNextMonth();
        return false;           // prevent browser default action
    });
};

EventCalendar.prototype.goToNextMonth = function () {
    var stateYear = this.state.year;
    var stateMonth = this.state.month;
    if (this.isLatestMonthOrLater(stateYear, stateMonth)) {
        return false;
    }
    stateMonth += 1;
    if (stateMonth > 11) {
        stateMonth -= 12;
        stateYear += 1;
    }
    this.state = { year: stateYear, month: stateMonth };
    this.populateMonthChooser();
    this.populateCalendar();
    return true;
};

EventCalendar.prototype.goToPreviousMonth = function () {
    var stateYear = this.state.year;
    var stateMonth = this.state.month;
    if (this.isThisMonthOrEarlier(stateYear, stateMonth)) {
        return false;
    }
    stateMonth -= 1;
    if (stateMonth < 0) {
        stateMonth += 12;
        stateYear -= 1;
    }
    this.state = { year: stateYear, month: stateMonth };
    this.populateMonthChooser();
    this.populateCalendar();
    return true;
};

// NO LONGER IN USE
EventCalendar.prototype.getFooterEvents = function (regionName) {
    var eventsUnfiltered, eventsFiltered = [], i, event;
    if (regionName) {
        eventsUnfiltered = this.regionData[regionName].events;
    } else {
        eventsUnfiltered = this.events;
    }
    for (i = 0; i < eventsUnfiltered.length; i += 1) {
        event = eventsUnfiltered[i];
        if (event.hasPassed) {
            continue;
        }
        eventsFiltered.push(event);
        if (eventsFiltered.length >= 2) {
            break;
        }
    }
    return eventsFiltered;
};

//=============================================================================

EventCalendar.prototype.getEventIdFromQueryString = function () {
    return this.params.get("id");
};

//=============================================================================

EventCalendar.prototype.populateSingleEvent = function (event) {
    var $ = jQuery;

    if (event) {
        var html = this.eventHTML(event, { single: true, noRegister: true });
        $("[data-event-single-wrapper]").empty().append(html);
    } else {
        $("[data-event-single-wrapper]").empty();
    }
};

/**
 * Called initially and when month changes.
 */
EventCalendar.prototype.populateCalendar = function () {
    var $ = jQuery;

    $("[data-event-calendar-container]").empty().append(
        this.eventCalendarHTML(this.state.year, this.state.month)
    );
};

/**
 * Called by <script> block in /events/index.htm.
 */
EventCalendar.prototype.populateEventsPage = function () {
    this.selectedLocationName = null;
    this.populateMonthChooser();
    this.populateCalendar();
};

EventCalendar.prototype.populateEventRegistrationPage = function () {
    var id = this.getEventIdFromQueryString(), $ = jQuery;

    if (!id) {
        return false;
    }

    var event = this.eventById[id];
    this.populateSingleEvent(event); // does the correct thing if there's no event

    if (!event) {
        return false;
    }

    document.getElementById("contactFormRecipient").value   = this.locationData[event.locationName].recipient;
    document.getElementById("contactFormConfirmPage").value = "https://www.monogram.com/events/register-thanks.htm?id=" + encodeURIComponent(id);
    if (document.body.getAttribute('data-page-type') === 'event-registration' &&
        document.body.getAttribute('data-event-registration-type') === 'ceu') {
        if (event.isClosed) {
            document.getElementById("contactFormSubject").value = this.locationData[event.locationName].mdcName3 + " CEU WAITLIST: " + event.shortlyFormattedDate;
        } else {
            document.getElementById("contactFormSubject").value = this.locationData[event.locationName].mdcName3 + " CEU Registration: " + event.shortlyFormattedDate;
        }
    } else {
        if (event.isClosed) {
            document.getElementById("contactFormSubject").value = this.locationData[event.locationName].mdcName3 + " WAITLIST: " + event.shortlyFormattedDate;
        } else {
            document.getElementById("contactFormSubject").value = this.locationData[event.locationName].mdcName3 + " Registration: " + event.shortlyFormattedDate;
        }
    }
    document.getElementById("contactFormEventTitle").value  = event.title;
    document.getElementById("contactFormEventDate").value   = event.formattedDate;
    document.getElementById("contactFormEventTimes").value  = event.formattedTimeRange2;
    if (event.isClosed) {
        $('[data-event-open]').addClass('hide');
        $('[data-event-closed]').removeClass('hide');
    }

    var trackEventName = decodeEntitiesStripHTML(event.title);
    var trackEventLocation = this.locationData[event.locationName].cityName;
    var trackEventDate = this.formatDateShort(event.startDateTime);

    var forms = Array.from(document.querySelectorAll('[data-event-registration-form]'));
    forms.forEach(function (form) {
        form.setAttribute('data-mg-track-event-name',     trackEventName);
        form.setAttribute('data-mg-track-event-location', trackEventLocation);
        form.setAttribute('data-mg-track-event-date',     trackEventDate);
    });

    return true;
};

EventCalendar.prototype.populateEventRegistrationThanksPage = function () {
    var id = this.getEventIdFromQueryString(), $ = jQuery;
    if (id) {
        var event = this.eventById[id];
        this.populateSingleEvent(event); // does the correct thing if there's no event
        if (event) {
            if (event.isClosed) {
                $('[data-event-open]').addClass('hide');
                $('[data-event-closed]').removeClass('hide');
            }
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * Called by /assets/js/design-center-page.[min.]js
 */
EventCalendar.prototype.populateDesignCenterPage = function (locationName) {
    this.selectedLocationName = locationName;
    this.populateMonthChooser();
    this.populateCalendar();
};

// NO LONGER IN USE
EventCalendar.prototype.populateFooterTabs = function () {
    var html = "", events, $ = jQuery;

    html += '<li role="presentation" class="active"><a href="#allevents" aria-controls="allevents" role="tab" data-toggle="tab">All Events</a></li>';
    this.regionNames.forEach(function (regionName) {
        events = this.getFooterEvents(regionName);
        if (events.length) {
            var regionData = this.regionData[regionName];
            html += '<li role="presentation"><a href="#' + regionData.id.htmlEntities() + '" aria-controls="midwest" role="tab" data-toggle="tab">' + regionName.htmlEntities() + '</a></li>';
        }
    }, this);

    $("#footer-events ul.nav.nav-tabs").empty().html(html);
};

// NO LONGER IN USE
EventCalendar.prototype.populateFooterEvents = function () {
    var html = "", events, $ = jQuery;

    events = this.getFooterEvents();
    if (events.length) {
        html += '<div role="tabpanel" class="tab-pane active" id="allevents">';
        events.forEach(function (event) {
            html += this.singleEventFooterHTML(event);
        }, this);
        html += '</div>';
    }

    this.regionNames.forEach(function (regionName) {
        events = this.getFooterEvents(regionName);
        if (events.length) {
            var regionData = this.regionData[regionName];
            html += '<div role="tabpanel" class="tab-pane" id="' + regionData.id.htmlEntities() + '">';
            events.forEach(function (event) {
                html += this.singleEventFooterHTML(event);
            }, this);
            html += '</div>';
        }
    }, this);

    $("#footer-events .tab-content").empty().html(html);
};

// NO LONGER IN USE
EventCalendar.prototype.populateFooter = function () {
    this.populateFooterEvents();
    this.populateFooterTabs();
};

//=============================================================================

jQuery(function ($) {
    if ($(".event-registration-form").length) {
        $(".event-registration-form").each(function () {
            var form = this;

            // because the form submission application does not work
            // with multiple identically-named checkboxes and having
            // it fixed would be a tall order...
            $(form).submit(function () {
                var $whichAppliances = $(form).find("input[type='checkbox'][name='temp_Which_Appliances']:checked");
                var whichAppliancesArray = $whichAppliances.toArray().map(function (element) { return element.value; });
                var whichAppliancesString = whichAppliancesArray.join("; ");
                form.txt18Which_Appliances.value = whichAppliancesString;
                return true;
            });

            if (typeof hyperform !== "undefined") {
                /* Handle groups of checkboxes (ones with the same
                   `name` attribute) where one of them has
                   required="at-least-one" specified.

                   NOTE: We might have to make this global if there
                   are any more forms with multiple checkboxes. */
                $(form).find("input[type='checkbox'][required='at-least-one']").each(function () {
                    var name, $checkboxes, $lastCheckbox, $customErrorMessage, $newCheckbox, lastCheckbox;
                    var $firstCheckbox, firstCheckbox;
                    var hasErrorsBelowInputs;

                    hasErrorsBelowInputs = form.classList.contains('form-errors-below-inputs');

                    name = $(this).attr("name");
                    $checkboxes = $(form).find("input[type='checkbox']").filter(function () {
                        return $(this).attr("name") === name;
                    });
                    $(this).removeAttr("required");

                    $lastCheckbox = $checkboxes.eq($checkboxes.length - 1);
                    $firstCheckbox = $checkboxes.eq(0);

                    lastCheckbox = $lastCheckbox.get(0); /* need a DOM reference. */
                    firstCheckbox = $firstCheckbox.get(0); /* need a DOM reference. */

                    if (hasErrorsBelowInputs) {

                        /*
                         * In a lot of layouts we can't use non-tooltip hyperform
                         * errors without placing them elsewhere
                         */

                        $customErrorMessage = $(form).find("[data-custom-error-message]").filter(function () {
                            return $(this).attr("for") === name;
                        });
                        if ($customErrorMessage.length) {
                            $newCheckbox = $("<input type='checkbox'>")
                                .attr({ name: name, value: "dummy" })
                                .css({ display: "none" });
                            $customErrorMessage.append($newCheckbox);
                            $lastCheckbox = $newCheckbox;
                            lastCheckbox = $lastCheckbox.get(0);
                        }

                        /* Register a custom validator for the last checkbox. */
                        hyperform.addValidator(lastCheckbox, function (element) {
                            var valid = $checkboxes.filter(":checked").length > 0;
                            element.setCustomValidity(
                                valid ? "" : "Please check at least one of the boxes if you want to proceed."
                            );
                            firstCheckbox.focus();
                            return valid;
                        });

                        /* When any of the checkboxes are clicked, revalidate. */
                        $checkboxes.click(function () {
                            lastCheckbox.reportValidity();
                        });
                    } else {    // we're using tooltips

                        /* Register a custom validator for the *first* checkbox. */
                        hyperform.addValidator(firstCheckbox, function (element) {
                            var valid = $checkboxes.filter(":checked").length > 0;
                            element.setCustomValidity(
                                valid ? "" : "Please check at least one of the boxes if you want to proceed."
                            );
                            return valid;
                        });

                        /* When any of the checkboxes are clicked, revalidate. */
                        $checkboxes.click(function () {
                            firstCheckbox.reportValidity();
                        });
                    }

                });

                hyperform(form);
            }
        });
    }
});
