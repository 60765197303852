/*global Monogram, jQuery, console, empty */

/**
 * Top Navigation, in the Top Header.
 */

Monogram.TopNav = {
    manipulateHTML: function () {
        var mobileMenu             = document.getElementById('top-nav-menu--mobile');
        var desktopMenu            = document.getElementById('top-nav-menu--desktop');
        var desktopDropdowns       = document.getElementById('top-nav-menu-dropdowns--desktop');
        var stickyDesktopMenu      = document.getElementById('top-nav-menu--sticky-desktop');
        var stickyDesktopDropdowns = document.getElementById('top-nav-menu-dropdowns--sticky-desktop');

        var hold1, hold2;

        var regExpIdNotEndingWithHyphenHyphenMobileSuffix =
            /(\s)(id)=(?!"[^"]*--mobile")"([^"]+)"/g;

        if (!mobileMenu) {
            return;
        }

        mobileMenu.querySelectorAll('[id]').forEach(function (element) {
            var id = element.id;
            if (empty(id)) {
                return;
            }
            if (!/--mobile$/.test(id)) {
                console.error("<%s id=\"%s\"> in #top-nav-menu--mobile does not have --mobile suffix!"
                              .replace(/%s/, element.tagName.toLowerCase())
                              .replace(/%s/, element.id));
            }
        });

        if (stickyDesktopMenu && stickyDesktopDropdowns) {
            // sticky desktop version of menu (without dropdowns)
            hold1 = document.createElement(stickyDesktopMenu.tagName.toLowerCase());

            // sticky desktop menu dropdowns
            hold2 = document.createElement(stickyDesktopDropdowns.tagName.toLowerCase());

            // replace any id NOT followed by `--mobile` by APPENDING `--sticky-desktop`
            // replace `--mobile` with `--sticky-destkop`
            hold1.innerHTML = mobileMenu.innerHTML
                .replace(regExpIdNotEndingWithHyphenHyphenMobileSuffix, '$1$2="$3--sticky-desktop"')
                .replace(/--mobile\b/g, '--sticky-desktop');

            hold1.querySelectorAll('[data-top-nav-dropdown]').forEach(function (element) {
                hold2.appendChild(element);
                element.innerHTML =
                    '<div class="container"><div class="row"><div class="col-xs-12">' +
                    element.innerHTML +
                    '</div></div></div>';
            });

            stickyDesktopMenu.innerHTML = hold1.innerHTML;
            stickyDesktopDropdowns.innerHTML = hold2.innerHTML;
        }

        if (desktopMenu && desktopDropdowns) {
            // desktop version of menu (without dropdowns)
            hold1 = document.createElement(desktopMenu.tagName.toLowerCase());

            // desktop menu dropdowns
            hold2 = document.createElement(desktopDropdowns.tagName.toLowerCase());

            // replace any id NOT followed by `--mobile` by APPENDING `--desktop`
            // replace `--mobile` with `--destkop`
            hold1.innerHTML = mobileMenu.innerHTML
                .replace(regExpIdNotEndingWithHyphenHyphenMobileSuffix, '$1$2="$3--desktop"')
                .replace(/--mobile\b/g, '--desktop');

            hold1.querySelectorAll('[data-top-nav-dropdown]').forEach(function (element) {
                hold2.appendChild(element);
                element.innerHTML =
                    '<div class="container"><div class="row"><div class="col-xs-12">' +
                    element.innerHTML +
                    '</div></div></div>';
            });

            desktopMenu.innerHTML = hold1.innerHTML;
            desktopDropdowns.innerHTML = hold2.innerHTML;
        }

        mobileMenu.querySelectorAll('.top-nav-anchor').forEach(function (anchor) {
            anchor.classList.add('collapsed');
        });
        mobileMenu.querySelectorAll('[data-toggle="top-nav-dropdown"]').forEach(function (anchor) {
            anchor.setAttribute('data-toggle', 'collapse');
        });
        mobileMenu.querySelectorAll('.top-nav-dropdown').forEach(function (dd) {
            dd.classList.add('collapse');
        });
    },
    highlightCorrectTopNavItemOnDesktop: function () {
        var highlightId = document.body.getAttribute('data-top-nav-highlight');
        if (empty(highlightId)) {
            return;
        }
        var highlightListItem = document.getElementById(highlightId + '--desktop');
        if (!highlightListItem) {
            return;
        }
        var toggle = highlightListItem.querySelector('[data-top-nav-anchor]');
        if (!toggle) {
            return;
        }
        toggle.classList.add('is-selected');
    },
    showStickyTopNavMenu: function () {
        document.querySelectorAll('[data-top-nav--sticky-desktop]').forEach(function (menu) {
            Monogram.Utilities.addThingyOpenClass('sticky-top-nav-is-active');
            menu.classList.add('is-active');
        });
    },
    hideStickyTopNavMenu: function () {
        document.querySelectorAll('[data-top-nav--sticky-desktop]').forEach(function (menu) {
            Monogram.Utilities.removeThingyOpenClass('sticky-top-nav-is-active');
            menu.classList.remove('is-active');
        });
    },
    showOrHideStickyTopNavMenu: function () {
        var topHeader = document.querySelector('[data-top-header-inner]');
        if (!topHeader) {
            this.hideStickyTopNavMenu();
            return;
        }
        var rect = topHeader.getBoundingClientRect();
        var stickyVisible = (rect.top + rect.height <= 0);
        if (stickyVisible) {
            this.showStickyTopNavMenu();
        } else {
            this.hideStickyTopNavMenu();
        }
    },
    addEventListeners: function () {
        var $ = jQuery;

        document.addEventListener('click', function (event) {
            var anchor;
            if ((anchor = event.target.closest('[data-toggle="top-nav-dropdown"]'))) {
                this.toggleDropdownFromAnchor(anchor, event);
            } else if (event.target.closest('[data-top-nav-dropdown]')) {
                // do nothing
            } else {
                this.closeAnyDropdowns();
            }
        }.bind(this));
        window.addEventListener('scroll', function (event) {
            this.showOrHideStickyTopNavMenu();
        }.bind(this));
        window.addEventListener('resize', function (event) {
            this.showOrHideStickyTopNavMenu();
        }.bind(this));
        $(window).on('keydown', function (event) {
            if (event.which === 27 /* Escape key */) {
                this.closeAnyDropdowns();
            }
        }.bind(this));
    },
    toggleDropdownFromAnchor: function (anchor, event) {
        var menu = anchor.closest('[data-top-nav]');
        if (!menu) {
            return;
        }
        var selector = anchor.getAttribute('data-target');
        if (!selector) {
            return;
        }
        var dropdown = document.querySelector(selector);
        if (!dropdown) {
            return;
        }
        var isActive = !dropdown.classList.contains('is-active');
        this.closeAnyDropdownsInMenu(menu);
        if (isActive) {
            anchor.classList.add('is-active');
            dropdown.classList.add('is-active');
        } else {
            anchor.classList.remove('is-active');
            dropdown.classList.remove('is-active');
        }
    },
    closeAnyDropdownsFromElement: function (element, event) {
        var menu = element.closest('[data-top-nav]');
        if (!menu) {
            return;
        }
        this.closeAnyDropdownsInMenu(menu);
    },
    closeAnyDropdownsInMenu: function (menu) {
        menu.querySelectorAll('[data-toggle="top-nav-dropdown"]').forEach(function (anchor) {
            anchor.classList.remove('is-active');
            var selector = anchor.getAttribute('data-target');
            if (!selector) {
                return;
            }
            var dropdown = document.querySelector(selector);
            if (!dropdown) {
                return;
            }
            dropdown.classList.remove('is-active');
        });
    },
    closeAnyDropdowns: function () {
        document.querySelectorAll('[data-toggle="top-nav-dropdown"]').forEach(function (anchor) {
            anchor.classList.remove('is-active');
            var selector = anchor.getAttribute('data-target');
            if (!selector) {
                return;
            }
            var dropdown = document.querySelector(selector);
            if (!dropdown) {
                return;
            }
            dropdown.classList.remove('is-active');
        });
    },
    init: function () {
        this.manipulateHTML();
        this.highlightCorrectTopNavItemOnDesktop(); // must occur AFTER html manipulations
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.TopNav.init();
});
