/*global Monogram, jQuery, console */

Monogram.SpecPage = {
    slickOptions: {
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
        swipeToSlide: true,
        dots: true,
        customPaging: function (slider, i) {
            var $ = jQuery;
            var text = (i + 1) + ' of ' + slider.slideCount;
            return $('<span>').text(text);
        }
    },
    initSlick: function () {
        var $ = jQuery;
        var selector = '[data-spec-page-product-photo-slider]';
        $(selector).slick(this.slickOptions);

        var timeout;
        $(document).on('setPosition', '[data-spec-page-product-photo-slider]', function (event, slickObject) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = undefined;
            }
            timeout = setTimeout(function () {
                Monogram.EqualHeight.updateEqualHeightCollections();
                timeout = undefined;
            }, 125);
        });
    },
    fancyBoxOptions: {
        loop: true,
        keyboard: true,
        preventCaptionOverlap: true,
        arrows: true,
        infobar: true,
        toolBar: true,
        modal: false,
        touch: {
            vertical: false
        },
        overlay: {
            locked: true,   // if true, the content will be locked into overlay
        },
        transitionEffect: 'slide',  //false - to disable  'fade' 'slide' 'circular' 'tube' 'zoom-in-out' 'rotate'
        transitionDuration: Monogram.settings.transitionDurationMs,
        animationEffect: 'fade',  //false - to disable  'fade' 'slide' 'circular' 'tube' 'zoom-in-out' 'rotate'
        animationDuration: Monogram.settings.transitionDurationMs,
        buttons: [
            'zoom',
            'share',
            'fullScreen',
            'thumbs',
            'close'
        ],
        thumbs: {
            autoStart: true,
            axis: 'x'
        },
        iframe: {
            preload: false
        },
        afterShow: function () {
            Monogram.Utilities.addThingyOpenClass('fancybox-is-active');
        },
        afterClose: function () {
            Monogram.Utilities.removeThingyOpenClass('fancybox-is-active');
        }
    },
    initFancyBox: function () {
        var $ = jQuery;
        $('[data-fancybox="spec-page-photo-gallery"]').fancybox(this.fancyBoxOptions);
    },
    init: function () {
        this.initFancyBox();    // must be executed before initSlick.
        this.initSlick();
    },
};

jQuery(function ($) {
    Monogram.SpecPage.init();
});
