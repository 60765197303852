/*global jQuery, Monogram, console */

Monogram.Animator = {
    requestUpdate: function () {
        if (this.frameId) {
            window.cancelAnimationFrame(this.frameId);
        }
        this.frameId = window.requestAnimationFrame(function () {
            this.update();
            delete this.frameId;
        }.bind(this));
    },
    addEventListeners: function () {
        window.addEventListener('scroll', this.requestUpdate.bind(this));
        window.addEventListener('resize', this.requestUpdate.bind(this));
    },
    init: function () {
        this.addEventListeners();
        this.requestUpdate();
    }
};

/**
 * ThisIsNotParallax.
 *
 * This is not actual parallax, but something in that vein.  It really
 * just enables a class on an element when you scroll down to where
 * it's partially visible, and disables that class when you scroll
 * back up to where it's not visible at all.
 */

Monogram.ThisIsNotParallax = Object.assign(Object.create(Monogram.Animator), {
    setVisibility: function (element, portionOfTop, portionOfBottom, maxAmountVisible, scrollDirection) {
        var data = Monogram.Utilities.elementData(element, 'not-parallax'); // data-not-parallax
        if (!('flag' in data)) {
            data.flag = false;
        }
        if (maxAmountVisible < 1) {
            portionOfTop    = Math.min(portionOfTop / maxAmountVisible, 1);
            portionOfBottom = Math.min(portionOfBottom / maxAmountVisible, 1);
        }
        if (scrollDirection === 'forward' || scrollDirection === 'initial') {
            if (portionOfTop >= 0.25) {
                data.flag = true;
            }
        } else if (scrollDirection === 'back') {
            if (portionOfTop <= 0) {
                data.flag = false;
            }
        }
        if (data.flag) {
            element.classList.add('not-parallax-in');
            element.classList.remove('not-parallax-out');
        } else {
            element.classList.remove('not-parallax-in');
            element.classList.add('not-parallax-out');
        }
    },
    disableElement: function (element) {
        element.classList.remove('not-parallax-enabled');
        element.classList.add('not-parallax-disabled');
    },
    enableElement: function (element) {
        element.classList.add('not-parallax-enabled');
        element.classList.remove('not-parallax-disabled');
    },
    getElements: function () {
        return Array.from(document.querySelectorAll('[data-not-parallax]'));
    },
    updateElements: function () {
        // for lack of a better name
        function scale(a, x, y) {
            return (a - x) / (y - x);
        }

        // for lack of a better name
        function winnow(a, x, y) {
            return (a < x) ? x : (a > y) ? y : a;
        }

        var bodyHeight = document.body.clientHeight;
        var windowHeight = window.innerHeight;
        var amountCanScroll = bodyHeight - windowHeight;

        if (this.lastPageYOffset === undefined) {
            this.scrollDirection = 'initial';
            this.lastPageYOffset = window.pageYOffset;
        } else if (this.lastPageYOffset < window.pageYOffset) {
            this.scrollDirection = 'forward';
            this.lastPageYOffset = window.pageYOffset;
        } else if (this.lastPageYOffset > window.pageYOffset) {
            this.scrollDirection = 'back';
            this.lastPageYOffset = window.pageYOffset;
        }                       // otherwise no change

        this.getElements().forEach(function (element) {
            var data = Monogram.Utilities.elementData(element, 'not-parallax'); // data-not-parallax

            function fail() {
                this.disableElement(element);
                data.enabled = false;
                delete data.pixelDifference;
            }

            // If <body> is not as tall as window, we cannot even
            // scroll, so there's no point in not-parallaxing.
            if (amountCanScroll <= 0) {
                fail.apply(this);
                return;
            }

            // The element needs a nonzero height.
            if (element.offsetHeight <= 0) {
                fail.apply(this);
                return;
            }

            var rect = element.getBoundingClientRect();

            var elementTop    = rect.top + window.pageYOffset;
            var elementBottom = elementTop + element.offsetHeight;
            var elementCenter = elementTop + element.offsetHeight / 2;

            var scrollCenter = window.pageYOffset + window.innerHeight / 2;

            var topAlwaysVisible    = ((elementTop - amountCanScroll) >= 0);
            var bottomAlwaysVisible = (elementBottom <= windowHeight);

            // If element is always fully visible, we don't want
            // not-parallax.
            if (topAlwaysVisible && bottomAlwaysVisible) {
                fail.apply(this);
                return;
            }

            // A floating-point number between 0 and 1, specifying the
            // maximum vertical amount of the element that can be
            // visible.  We use this for modifying calculations when
            // the entire element can never be visible at once.
            var maxAmountVisible = Math.min(windowHeight / element.offsetHeight, 1);

            var scrollCenterAtTop    = windowHeight / 2;
            var scrollCenterAtBottom = windowHeight / 2 + amountCanScroll;

            var minOffsetForThisIsNotParallax = scrollCenterAtTop - elementCenter;
            var maxOffsetForThisIsNotParallax = scrollCenterAtBottom - elementCenter;
            var offsetsAreGood = (minOffsetForThisIsNotParallax <= (-windowHeight / 4)) && (maxOffsetForThisIsNotParallax >= (windowHeight / 4));

            // Is it possible to scroll to where the vertical center
            // of the window meets the vertical center of the element?
            // And once we're at that point can we scroll in either
            // direction (up or down) by at least 1/4 of the window
            // height?  If no to any of these questions, I don't want
            // to do not-parallax.
            if (!offsetsAreGood) {
                fail.apply(this);
                return;
            }

            // Starts at 0, starts increasing once we see the top of
            // the element as we're scrolling, becomes 1 when the
            // element is visible, is 1 at the end.
            var portionOfTop = winnow(scale(window.pageYOffset + windowHeight, elementTop, elementBottom), 0, 1);

            // Starts at 1, starts decreasing once the top of the
            // element is no longer visible as we're scrolling, becomes
            // 0 when the element is hidden, is 0 at the end.
            var portionOfBottom = winnow(scale(window.pageYOffset, elementBottom, elementTop), 0, 1);

            // toggles a bunch of visibility classes.
            this.setVisibility(element, portionOfTop, portionOfBottom, maxAmountVisible, this.scrollDirection);

            data.pixelDifference = scrollCenter - elementCenter;
            data.enabled = true;
            this.enableElement(element);
        }.bind(this));
    },
    update: function () {
        var mq = window.matchMedia('screen and (min-width: ' + Monogram.breakpoints.sm + 'px)');
        if (!mq.matches ||
            !document.querySelector('[data-not-parallax]') ||
            document.body.clientHeight <= window.innerHeight) {
            document.documentElement.classList.remove('not-parallax-active');
            document.querySelectorAll('[data-not-parallax]').forEach(function (element) {
                this.disableElement(element);
            }.bind(this));
            return;
        }
        document.documentElement.classList.add('not-parallax-active');
        this.updateElements();
    }
});

jQuery(function ($) {
    Monogram.ThisIsNotParallax.init();
});
