/*global Monogram, jQuery, console, isStaticEnvironment, isNonStaticTestingEnvironment, URLSearchParams, empty, fetch, alert */

Monogram.ProductLineResults = {
    getProductSubcategoryName: function () {
        return document.body.getAttribute('data-product-line');
    },

    isMobile: function () {
        var mq = window.matchMedia('screen and (min-width: ' + Monogram.breakpoints.sm + 'px)');
        return !mq.matches;
    },

    // Given 'https://appliances.monogram.com/us/kitchen/full-size-refrigerators',
    // returns 'full-size-refrigerators'.
    getProductSubcategoryId: function () {
        var matches = /\/us\/kitchen\/([^\/]+)/.exec(location.pathname);
        if (matches && matches[1]) {
            return matches[1];
        } else if (location.hash === '#example2') {
            return 'example2'; // assuming static/development page
        } else {
            return 'example'; // assuming static/development page
        }
    },

    populateNothing: function () {
        document.querySelectorAll('[data-callout-container]').forEach(function (element) {
            element.parentNode.removeChild(element);
        });
        document.querySelectorAll('[data-learn-more-container]').forEach(function (element) {
            element.parentNode.removeChild(element);
        });
    },

    setBackgroundImageUrl: function (element, url) {
        element.style.backgroundImage = 'url("' + url + '")';
    },

    populateBackgroundImage: function (hash) {
        var bg       = hash.backgroundImage;
        var bgMobile = hash.backgroundImageMobile;
        if (bg) {
            bg = this.fixImageUrl(bg);
        }
        if (bgMobile) {
            bgMobile = this.fixImageUrl(bgMobile);
        }
        if (bg) {
            if (bgMobile) {
                document.querySelectorAll('[data-background-image]').forEach(function (element) {
                    if (element.classList.contains('visible-xs-block')) {
                        this.setBackgroundImageUrl(element, bgMobile);
                    } else if (element.classList.contains('hidden-xs')) {
                        this.setBackgroundImageUrl(element, bg);
                    } else {
                        this.setBackgroundImageUrl(element, bg);
                    }
                }.bind(this));
            } else {
                document.querySelectorAll('[data-background-image]').forEach(function (element) {
                    if (element.classList.contains('visible-xs-block')) {
                        element.parentNode.removeChild(element);
                    } else if (element.classList.contains('hidden-xs')) {
                        element.classList.remove('hidden-xs');
                        this.setBackgroundImageUrl(element, bg);
                    } else {
                        this.setBackgroundImageUrl(element, bg);
                    }
                }.bind(this));
            }
        } else {
            document.querySelectorAll('[data-background-image]').forEach(function (element) {
                element.parentNode.removeChild(element);
            }.bind(this));
        }
    },

    populateLearnMoreUrl: function (hash) {
        var url = hash.learnMoreUrl;
        if (url) {
            document.querySelectorAll('[data-learn-more-link]').forEach(function (anchor) {
                url = this.fixPageUrl(url);
                anchor.href = url;
            }.bind(this));
        } else {
            document.querySelectorAll('[data-learn-more-container]').forEach(function (anchor) {
                anchor.parentNode.removeChild(anchor);
            }.bind(this));
        }
    },

    populateCallout: function (hash) {
        var photo1 = hash.photo1;
        var photo2 = hash.photo2;
        var calloutText = hash.calloutText;
        if (!photo1 || !photo2 || !calloutText) {
            document.querySelectorAll('[data-callout-container]').forEach(function (element) {
                element.parentNode.removeChild(element);
            });
            return;
        }
        if (photo1) {
            photo1 = this.fixImageUrl(photo1);
            document.querySelectorAll('[data-callout-photo-1]').forEach(function (img) {
                img.src = photo1;
            });
        }
        if (photo2) {
            photo2 = this.fixImageUrl(photo2);
            document.querySelectorAll('[data-callout-photo-2]').forEach(function (img) {
                img.src = photo2;
            });
        }
        if (calloutText) {
            document.querySelectorAll('[data-callout-text]').forEach(function (element) {
                element.innerHTML = calloutText;
            });
        } else {
            document.querySelectorAll('[data-callout-text]').forEach(function (element) {
                element.parentNode.removeChild(element);
            });
        }
    },

    fixUrl: function (url) {
        var newLocation = document.createElement('a');
        newLocation.href = url;
        if (isStaticEnvironment()) {
            if (/(^|\.)monogram(\.(com|localhost))?$/.test(location.hostname) &&
                /(^|\.)monogram(\.(com|localhost))?$/.test(newLocation.hostname)) {
                newLocation.hostname = location.hostname;
                newLocation.protocol = location.protocol;
                newLocation.username = location.username || '';
                newLocation.password = location.password || '';
            }
        } else if (isNonStaticTestingEnvironment()) {
            if (newLocation.hostname === 'www.monogram.com') {
                newLocation.hostname = 'test.monogram.com';
            }
        }
        return newLocation.href;
    },

    fixImageUrl: function (url) {
        return this.fixUrl(url);
    },

    fixPageUrl: function (url) {
        return this.fixUrl(url);
    },

    fixScriptUrl: function (url) {
        var newLocation = document.createElement('a');
        newLocation.href = url;
        if (isNonStaticTestingEnvironment()) {
            if (newLocation.hostname === 'www.monogram.com') {
                newLocation.hostname = 'test.monogram.com';
            }
        } else if (isStaticEnvironment()) {
            if (/(^|\.)monogram(\.(com|localhost))?$/.test(location.hostname) &&
                /(^|\.)monogram(\.(com|localhost))?$/.test(newLocation.hostname)) {
                newLocation.hostname = location.hostname;
                newLocation.protocol = location.protocol;
                newLocation.username = location.username || '';
                newLocation.password = location.password || '';
            }
        }
        return newLocation.href;
    },

    populatePage: function () {
        var $ = jQuery;
        var url = 'https://www.monogram.com/assets2/js/results-marketing.js';
        if (isStaticEnvironment()) {
            url = this.fixScriptUrl(url);
        } else if (isNonStaticTestingEnvironment()) {
            url = this.fixScriptUrl(url);
        }
        $.getScript(url, function (data, textStatus, jqxhr) {
            var name = this.getProductSubcategoryName();
            var id = this.getProductSubcategoryId();
            var hash = this.productPageRelationships.productSubcategories[id];
            if (!hash) {
                this.populateNothing();
                return;
            }
            this.populateBackgroundImage(hash);
            this.populateLearnMoreUrl(hash);
            this.populateCallout(hash);
        }.bind(this));
    },

    clearAllCheckboxes: function () { // :-)
        document.querySelectorAll('[data-results-filter-checkbox]').forEach(function (checkbox) {
            checkbox.checked = false;
        }.bind(this));
    },

    updateSort: function (sortValue) {
        document.querySelectorAll('[data-form-results-filters]').forEach(function (form) {
            Array.from(form.elements).filter(function (element) {
                return element.name === 'sort';
            }).forEach(function (element) {
                element.value = sortValue;
            });
        });
    },

    updateResults: function (options) {
        if (options && options.forcePageLoad) {
            this.submitDesktopFilterForm(options);
        } else if (this.isMobile()) {
            this.updateMobileResults(options);
        } else {
            this.submitDesktopFilterForm(options);
        }
    },

    submitDesktopFilterForm: function (options) {
        var form = document.querySelector('[data-form-results-filters-desktop]');
        if (!form) {
            return;
        }
        form.submit();
    },

    submitMobileFilterForm: function (options) {
        var form = document.querySelector('[data-form-results-filters-mobile]');
        if (!form) {
            return;
        }
        form.submit();
    },

    getParams: function (form) {
        var params = new URLSearchParams();
        if (!form) {
            return params;
        }
        Array.from(form.elements).forEach(function (element) {
            var tagName = element.tagName.toLowerCase();
            var type = element.type;
            var include = false;
            if (tagName === 'input') {
                if (type === 'radio' || type === 'checkbox') {
                    include = element.checked;
                } else if (type === 'button' || type === 'submit' || type === 'reset' || type === 'image') {
                    include = false;
                }
            } else if (tagName === 'button') {
                include = false;
            } else {
                include = true;
            }
            if (include) {
                if (tagName === 'select') {
                    params.append(element.name, element.options[element.selectedIndex].value);
                } else {
                    params.append(element.name, element.value);
                }
            }
        }.bind(this));
        return params;
    },

    updateMobileFilterFlags: function (form, filters) {
        filters.forEach(function (filter) {
            var name = filter.name;
            var values = filter.values;
            var checkboxes = Array.from(form.elements).filter(function (element) {
                return element.tagName.toLowerCase === 'input' &&
                    element.type === 'checkbox' &&
                    element.name === name;
            }.bind(this));
            values.forEach(function (hash) {
                var value = hash.value;
                var disabled = hash.disabled;
                var valueCheckboxes = checkboxes.filter(function (checkbox) {
                    return checkbox.value === value;
                });
                valueCheckboxes.forEach(function (checkbox) {
                    checkbox.disabled = disabled;
                    var parent = checkbox.closest('.checkbox');
                    if (parent) {
                        if (disabled) {
                            checkbox.classList.add('disabled');
                        } else {
                            checkbox.classList.remove('disabled');
                        }
                    }
                });
            });
        }.bind(this));
    },

    trackFilterChange: function (options) {
        var changedCheckbox = options && options.changedCheckbox;
        if (!changedCheckbox) {
            return;
        }
        var checked = changedCheckbox.checked;

        var filterCategory = changedCheckbox.getAttribute('data-filter-category');
        var filterTerm = changedCheckbox.getAttribute('data-filter-term');
        if (empty(filterCategory)) {
            filterCategory = undefined;
        }
        if (empty(filterTerm)) {
            filterTerm = undefined;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'filter-results',
            'filterCategory': filterCategory,
            'filterTerm': filterTerm,
            'filterPriceMin': undefined, // no price filters on monogram
            'filterPriceMax': undefined
        });
    },

    updateMobileResults: function (options) {
        var changedCheckbox = options && options.changedCheckbox;
        var url = '/us/monogram-category-json/' + this.getProductSubcategoryId();
        if (isStaticEnvironment()) {
            url = '/MONOGRAM-2020/product-filter-data/' + this.getProductSubcategoryId() + '.json';
        }
        var form = document.querySelector('[data-form-results-filters-mobile]');
        if (!form) {
            return;
        }
        var params = this.getParams(form);
        url = url + '?' + params.toString();
        Monogram.LoadingSpinner.show();
        this.httpFetch = fetch(url)
            .then(function (response) {
                return response.json();
            }.bind(this))
            .then(function (data) {
                var numberOfResults = data.numberOfResults;
                document.querySelectorAll('[data-result-count]').forEach(function (element) {
                    element.innerHTML = numberOfResults;
                }.bind(this));
                this.updateMobileFilterFlags(form, data.filters);
                Monogram.LoadingSpinner.hide();
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                alert("We're sorry, we encountered a problem while attempting to retrieve product information.  Please try again later.");
                if (changedCheckbox) {
                    changedCheckbox.checked = !changedCheckbox.checked;
                }
                Monogram.LoadingSpinner.hide();
            }.bind(this));
    },

    addEventListeners: function () {
        var $ = jQuery;

        // Event handler for checkbox toggles.
        document.addEventListener('change', function (event) {
            if (!event.target.matches('[data-results-filter-checkbox]')) {
                return;
            }
            this.trackFilterChange({ changedCheckbox: event.target });
            this.updateResults({ changedCheckbox: event.target });
        }.bind(this));

        // Event handler for clicks on "Clear All Results" links or buttons.
        document.addEventListener('click', function (event) {
            var anchor = event.target;
            if (!anchor.hasAttribute('data-clear-all-filters')) {
                return;
            }
            var isInMobileFilterDropdown = anchor.closest('[data-form-results-filters-mobile]') ? true : false;
            if (isInMobileFilterDropdown) {
                event.preventDefault();
                this.clearAllCheckboxes();
                this.updateResults();
            } else {
                event.preventDefault();
                this.clearAllCheckboxes();
                this.updateResults({ forcePageLoad: true });
            }
        }.bind(this));

        // Event handler for clicks on sort dropdown items.
        document.addEventListener('click', function (event) {
            var anchor = event.target;
            if (!anchor.hasAttribute('data-results-sort')) {
                return;
            }
            var value = anchor.getAttribute('data-value');
            if (empty(value)) {
                return;
            }
            event.preventDefault();
            this.updateSort(value);
            this.updateResults();
        }.bind(this));

        // Event handler for "View X Products" in Mobile Filter Results
        document.addEventListener('click', function (event) {
            var anchor = event.target;
            if (!anchor.hasAttribute('data-refresh-results')) {
                return;
            }
            event.preventDefault();
            this.submitMobileFilterForm();
        }.bind(this));

        // Code related to mobile results filters dropdowns.  The
        // *.bs.collapse event handlers absolutely require jQuery.
        // The keydown handler doesn't, but backward-compatible
        // keyboard event handlers written in vanilla JS are kind of
        // cumbersome.
        $(document).on('show.bs.collapse', '.results-filters-mobile', function (event) {
            if ($(event.target).is('.results-filters-mobile')) {
                Monogram.Utilities.addThingyOpenClass('results-filters-is-active');
            }
        });
        $(document).on('shown.bs.collapse', '.results-filters-mobile', function (event) {
            if ($(event.target).is('.results-filters-mobile')) {
                Monogram.Utilities.addThingyOpenClass('results-filters-is-active');
            }
        });
        $(document).on('hidden.bs.collapse', '.results-filters-mobile', function (event) {
            if ($(event.target).is('.results-filters-mobile')) {
                Monogram.Utilities.removeThingyOpenClass('results-filters-is-active');
            }
        });
        $(window).on('keydown', function (event) {
            if (event.which === 27 /* Escape key */) {
                $('.collapse.results-filters-mobile').collapse('hide');
            }
        });
    },

    init: function () {
        if (document.body.getAttribute('data-page-type') !== 'product-line-results') {
            return;
        }
        this.populatePage();
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.ProductLineResults.init();
});
