/*global Monogram, jQuery */

// Compute the centerPadding to specify for slick, given the following:
//
// - slidesToShow
//
// - fraction, the amount of the previous slide to keep visible
//             and amount of next slide to keep visible.
//             e.g., 1/4 for a quarter of the previous/next slides
Monogram.Utilities.computeCenterPadding = function (slidesToShow, fraction) {
    if (!fraction) {
        fraction = 1/3;
    }
    var visibleNumberOfSlides = slidesToShow + fraction * 2;
    var percent = 100 * (1 - slidesToShow / visibleNumberOfSlides) / 2;
    return percent.toFixed(4) + '%';
};

Monogram.ProductCategorySliders = {
    slickOptions: {
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
        centerMode: true,
        swipeToSlide: true,
        variableWidth: false,
        arrows: false,
        centerPadding: Monogram.Utilities.computeCenterPadding(1),
        responsive: [
            {
                breakpoint: Monogram.breakpoints.xxsSpecial - 1,
                settings: {
                    slidesToShow: 2,
                    centerPadding: Monogram.Utilities.computeCenterPadding(2)
                }
            },
            {
                breakpoint: Monogram.breakpoints.xsSpecial - 1,
                settings: {
                    slidesToShow: 3,
                    centerPadding: Monogram.Utilities.computeCenterPadding(3)
                }
            },
            {
                breakpoint: Monogram.breakpoints.sm - 1,
                settings: {
                    centerMode: false,
                    arrows: true,
                    variableWidth: false,
                    slidesToShow: 3,
                    centerPadding: 0
                }
            },
            {
                breakpoint: Monogram.breakpoints.md - 1,
                settings: {
                    centerMode: false,
                    arrows: true,
                    variableWidth: false,
                    slidesToShow: 4,
                    centerPadding: 0
                }
            },
            {
                breakpoint: Monogram.breakpoints.lg - 1,
                settings: {
                    centerMode: false,
                    arrows: true,
                    variableWidth: false,
                    slidesToShow: 5,
                    centerPadding: 0
                }
            }
        ]
    },
    updateEqualHeights: function (element) {
        var $ = jQuery;
        $('[data-product-category-slider]').each(function (slider) {
            Monogram.EqualHeight.updateEqualHeightCollections(slider);
        });
    },
    addSlickEventListeners: function () {
        // FIXME: This needs to be generalized, and not limited to any specific sliders.
        var $ = jQuery;
        var $document = $(document);
        var handler = function (event, slick) {
            if (!('$dots' in slick)) { // if not a slick object
                return;
            }
            this.updateEqualHeights();
        }.bind(this);
        $document.on('destroy', handler);
        $document.on('init', handler);
        $document.on('reInit', handler);
        $document.on('setPosition', handler);
        $document.on('swipe', handler);
        $document.on('lazyLoaded', handler);
        $document.on('afterChange', handler);
    },
    initSlickSliders: function () {
        var $ = jQuery;
        $('[data-product-category-slider]').slick(this.slickOptions);
    },
    init: function () {
        this.updateEqualHeights();
        this.addSlickEventListeners();
        this.initSlickSliders();
    }
};

jQuery(function ($) {
    Monogram.ProductCategorySliders.init();
});
