/*global jQuery, Monogram, console, isStaticEnvironment, encodeEntities, alert, Promise */

Monogram.ManualsAndSpecifications = {

    categories: [],
    categoriesByName: {},
    subcategoriesByName: {},
    productsBySku: {},
    finalized: false,
    initialized: false,
    populated: false,
    callShowEventHandler: true, // for avoiding event loops.

    // Product data as of 2019-11-21 has the eight keys of this object
    // as <DOC> value strings.
    docDisplayName: {
        '2D CAD - Elevation Download':        'CAD Download - 2D Elevation',
        '2D CAD - Plan Download':             'CAD Download - 2D Plan',
        '3D CAD - Download':                  'CAD Download - 3D',
        'Products.Energy Guide':              'Energy Guide',
        'Products.Installation Instructions': 'Installation Instructions',
        'Products.Quick Specs':               'Quick Specs',
        'Products.Retrofit Quick Specs':      'Retrofit Quick Specs',
        'Products.Use and Care Manual':       'Use and Care Manual'
    },

    createElementFromHTML: function (html) {
        var div = document.createElement('div');
        div.innerHTML = html;
        if (div.children.length === 1) {
            div = div.children[0];
        }
        return div;
    },

    // called by the page itself
    addCategory: function (name) {
        var category = {
            name: name,
            subcategories: []
        };
        this.currentCategory = category;
        this.categoriesByName[name] = category;
        this.categories.push(category);
    },

    // called by the page itself
    addSubcategory: function (name, productCount) {
        var subcategory = {
            name: name
        };
        this.subcategoriesByName[name] = subcategory;
        this.currentCategory.subcategories.push(subcategory);
        if (productCount !== null && productCount !== undefined) {
            subcategory.productCount = productCount;
        }
    },

    subcategoryUrl: function (subcategoryName) {
        if (isStaticEnvironment()) {
            return '/MONOGRAM-2020/product-xml-data/' + subcategoryName.toLowerCase().replace(/\s+/g, '-') + '.xml';
        } else {
            return '/us/manuals-specifications?TYPE=XML&CATEGORY=' + encodeURIComponent(subcategoryName);
        }
    },

    populate: function () {
        if (!this.finalized || !this.initialized || this.populated) {
            return;
        }
        var categoryTemplate    = document.getElementById('mands-category-template');
        var subcategoryTemplate = document.getElementById('mands-subcategory-template');
        var productCardTemplate = document.getElementById('mands-card-template');
        var downloadTemplate    = document.getElementById('mands-download-template');
        if (!categoryTemplate) {
            console.error('no category template');
            return;
        }
        if (!subcategoryTemplate) {
            console.error('no subcategory template');
            return;
        }
        if (!productCardTemplate) {
            console.error('no product card template');
            return;
        }
        if (!downloadTemplate) {
            console.error('no download template');
            return;
        }

        this.categoryTemplate    = categoryTemplate;
        this.subcategoryTemplate = subcategoryTemplate;
        this.productCardTemplate = productCardTemplate;
        this.downloadTemplate    = downloadTemplate;

        var categoryList = document.querySelector('[data-mands-category-list]');
        if (!categoryList) {
            console.error('no category list element');
            return;
        }

        this.categories.forEach(function (category) {
            var html = categoryTemplate.innerHTML;
            html = html.replace(/\{categoryName\}/g, encodeEntities(category.name));

            var div = this.createElementFromHTML(html);
            category.element = div;
            categoryList.appendChild(div);

            var subcategoryList = div.querySelector('[data-mands-subcategory-list]');
            if (!subcategoryList) {
                console.error('no subcategory list element');
                return;
            }

            category.subcategories.forEach(function (subcategory) {
                var id = subcategory.name.toLowerCase().replace(/\s+/g, '-');
                var html = subcategoryTemplate.innerHTML;
                html = html.replace(/\{subcategoryName\}/g, encodeEntities(subcategory.name));
                html = html.replace(/\{subcategoryId\}/g, encodeEntities(id));
                html = html.replace(/\{\{subcategoryProductCount\?(.*?)\}\}/g,
                                    subcategory.productCount ? '$1' : '');
                html = html.replace(/\{subcategoryProductCount\}/g, subcategory.productCount);

                var div = this.createElementFromHTML(html);
                subcategory.element = div;
                subcategory.populated = false;
                subcategoryList.appendChild(div);

                var productListElement = div.querySelector('[data-mands-list]');
                if (productListElement) {
                    subcategory.productListElement = productListElement;
                }
            }.bind(this));
        }.bind(this));
    },

    // @param node, a reference to a node such as:
    //     <foo>
    //         <moo>5</moo>
    //         <meow>13</meow>
    //     </foo>
    // @param tagName, a string like 'moo' or 'meow'.
    // @return a string like '5' or '13'.
    getXmlText: function (node, tagName) {
        try {
            return node.getElementsByTagName(tagName)[0].childNodes[0].data;
        } catch (e) {
            return null;
        }
    },

    // return true, returns false, or throws an error.
    populateSubcategoryFromXml: function (subcategory, xmlDoc) {
        var name = subcategory.name;
        var element = subcategory.productListElement;
        if (!element) {
            throw new Error(name + ': no HTML product list element');
        }
        var modelNodes = Array.from(xmlDoc.getElementsByTagName('Model'));
        if (!modelNodes.length) {
            throw new Error(name + ': no models returned from XML response');
        }
        subcategory.productCount = modelNodes.length;
        subcategory.element.querySelectorAll('[data-mands-product-count]').forEach(function (countElement) {
            countElement.innerHTML = '(' + subcategory.productCount + ')';
        });
        subcategory.products = [];
        modelNodes.forEach(function (modelNode) {
            var sku              = this.getXmlText(modelNode, 'SKU');
            var shortDescription = this.getXmlText(modelNode, 'Short_Description');
            var description      = this.getXmlText(modelNode, 'Description');
            var image            = this.getXmlText(modelNode, 'Image');
            var name             = shortDescription || description || sku;

            var specUrl = '/us/specs/' + encodeURIComponent(sku);
            var product = {
                sku:              sku,
                shortDescription: shortDescription,
                description:      description,
                name:             name,
                specUrl:          specUrl,
                modelNode:        modelNode,
                imageUrl:         image,
                image:            image
            };
            subcategory.products.push(product);
            this.productsBySku[sku] = product;
            this.populateDownloadLinks(sku);
            subcategory.productListElement.appendChild(this.createProductElement(product));
        }.bind(this));
        return true;
    },

    createProductElement: function (product) {
        var html = this.productCardTemplate.innerHTML;
        html = html.replace(/\{productSku\}/g, encodeEntities(product.sku));
        html = html.replace(/\{productName\}/g, encodeEntities(product.name));
        html = html.replace(/\{productImage\}/g, encodeEntities(product.image));
        html = html.replace(/\{productImageUrl\}/g, encodeEntities(product.imageUrl));
        html = html.replace(/\{productSpecUrl\}/g, encodeEntities(product.specUrl));
        var element = this.createElementFromHTML(html);
        var downloadListElement = element.querySelector('[data-mands-download-file-list]');
        if (downloadListElement) {
            product.downloads.forEach(function (download) {
                downloadListElement.appendChild(
                    this.createDownloadElement(download)
                );
            }.bind(this));
        }
        return element;
    },

    createDownloadElement: function (download) {
        var html = this.downloadTemplate.innerHTML;
        html = html.replace(/\{downloadUrl\}/g, encodeEntities(download.url));
        html = html.replace(/\{downloadName\}/g, encodeEntities(download.displayName));
        var element = this.createElementFromHTML(html);
        return element;
    },

    populateDownloadLinks: function (sku) {
        var product = this.productsBySku[sku];
        if (!product) {
            return;
        }
        product.downloads = [];
        Array.from(product.modelNode.children).forEach(function (node) {
            if (node.tagName === 'SKU' || node.tagName === 'Short_Description' || node.tagName === 'Description' || node.tagName === 'Image') {
                return;
            }
            var docUrl = this.getXmlText(node, 'NAME');
            var docName = this.getXmlText(node, 'DOC');
            if (!docUrl || !docName) {
                return;
            }
            var docDisplayName = (docName in this.docDisplayName) ? this.docDisplayName[docName] : docName;
            docDisplayName = docDisplayName.replace(/^products\./i, '');
            product.downloads.push({
                name:        docName,
                displayName: docDisplayName,
                url:         docUrl,
                node:        node
            });
        }.bind(this));
    },

    addEventListeners: function () {
        var $ = jQuery;
        $(document).on('show.bs.collapse', '[data-mands-subcategory-collapse]', function (event) {

            // Avoid an event loop.
            if (!this.callShowEventHandler) {
                this.callShowEventHandler = true;
                return;         // Next call will
            }

            // We have to go through the above rigmarole because we
            // can potentially call $(collapse).collapse('show');
            // later on, which would otherwise trigger the code below
            // this point to be executed again.

            var collapse = event.target;
            var subcategoryName = collapse.getAttribute('data-mands-subcategory-collapse');
            if (!subcategoryName) {
                return;
            }
            var subcategory = this.subcategoriesByName[subcategoryName];
            if (!subcategory) {
                return;
            }
            if (subcategory.populated) {
                return;         // Just open the collapse element.
            }

            // Do not open the collapse element at a later time unless
            // the code required to do so is called later.  We do that
            // on success, and not on failure.
            event.preventDefault();

            Monogram.LoadingSpinner.show();

            var url = this.subcategoryUrl(subcategoryName);
            this.httpFetch = fetch(url)
                .then(function (response) {
                    // nonsense required to fetch XML, part 1
                    this.responseHttp = response;
                    var contentType = response.headers.get('Content-Type');
                    var matches;
                    var charset;
                    if (contentType) {
                        if ((matches = /\;\s*charset\s*=\s*([^ \t\;\,]+)/i.exec(contentType))) {
                            charset = matches[1];
                        }
                    }
                    this.responseCharset = charset;
                    return response.arrayBuffer();
                }.bind(this))
                .then(function (arrayBuffer) {
                    // nonsense required to fetch XML, part 2
                    var decoder = new TextDecoder(this.responseCharset);
                    return decoder.decode(arrayBuffer);
                }.bind(this))
                .then(function (responseText) {
                    // nonsense required to fetch XML, part 3
                    this.responseText = responseText;
                    return (new DOMParser()).parseFromString(responseText, 'text/xml');
                }.bind(this))
                .then(function (responseXml) {
                    this.responseXml = responseXml;
                    if (this.populateSubcategoryFromXml(subcategory, responseXml)) {
                        Monogram.LoadingSpinner.hide();
                        subcategory.populated = true;

                        // This would be the code required to show the
                        // collapse element, in a way that avoids an
                        // event loop.
                        this.callShowEventHandler = false;
                        $(collapse).collapse('show');
                    } else {
                        throw new Error(subcategoryName + ': XML failure');
                    }
                }.bind(this))
                .catch(function (error) {
                    console.error(error);
                    alert("We're sorry, we encountered a problem while attempting to retrieve product information.  Please try again later.");
                    Monogram.LoadingSpinner.hide();
                }.bind(this));
        }.bind(this));
    },

    finalize: function () {     // called by the page itself
        this.finalized = true;
        if (this.initialized && !this.populated) {
            this.populate();
        }
    },

    init: function () {         // called below
        this.initialized = true;
        if (this.finalized && !this.populated) {
            this.populate();
        }
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.ManualsAndSpecifications.init();
});
