/*global console, jQuery, Monogram */

// Usage:
//
//     <div data-sticky-parent>
//         ...
//         <div data-sticky [data-sticky-offset="80"] [data-sticky-breakpoint="sm"]>
//             ...
//         </div>
//         ...
//     </div>
//
// If you experience performance issues, try the following:
//
//     <div data-sticky-parent>
//         ...
//         <div data-sticky [data-sticky-offset="80"] [data-sticky-breakpoint="sm"]>
//             <div data-sticky-child>
//                 ...
//             </div>
//         </div>
//         ...
//     </div>
//
Monogram.Sticky = {
    updateSticky: function (element) {
        var child = element.querySelector('[data-sticky-child]') || element;

        Monogram.Utilities.setTransform(child, '');

        var parent = element.closest('[data-sticky-parent]');
        if (!parent) {
            return;
        }

        var offsetTop = element.dataset.stickyOffsetTop;
        if (!offsetTop) {
            offsetTop = 0;
        }
        var offsetBottom = element.dataset.stickyOffsetBottom;
        if (!offsetBottom) {
            offsetBottom = 0;
        }

        var breakpoint = element.dataset.stickyBreakpoint;
        if (breakpoint in Monogram.breakpoints) {
            breakpoint = Monogram.breakpoints[breakpoint];
        }
        if (!breakpoint) {
            breakpoint = 0;
        }

        var mq;
        if (breakpoint) {
            mq = window.matchMedia('screen and (min-width: ' + breakpoint + 'px)');
            if (!mq.matches) {
                return;
            }
        }

        var elementRect = element.getBoundingClientRect();
        var parentRect  = parent.getBoundingClientRect();
        var scrollAmount = parentRect.height - elementRect.height;

        if (elementRect.height > window.innerHeight || scrollAmount <= 0) {
            return;
        }

        var scrollMin = elementRect.top + window.pageYOffset - offsetTop;
        var scrollMax = scrollMin + scrollAmount - offsetBottom;
        if (window.pageYOffset < scrollMin) {
            return;
        }
        if (window.pageYOffset <= scrollMax) {
            Monogram.Utilities.setTransform(child, 'translateY(' + (window.pageYOffset - scrollMin) + 'px)');
            return;
        }
        Monogram.Utilities.setTransform(child, 'translateY(' + (scrollMax - scrollMin) + 'px)');
    },
    update: function () {
        document.querySelectorAll('[data-sticky]').forEach(function (element) {
            this.updateSticky(element);
        }.bind(this));
    },
    requestUpdate: function () {
        if (this.frameId) {
            window.cancelAnimationFrame(this.frameId);
        }
        this.frameId = window.requestAnimationFrame(function () {
            this.update();
            delete this.frameId;
        }.bind(this));
    },
    addEventListeners: function () {
        window.addEventListener('scroll', this.requestUpdate.bind(this));
        window.addEventListener('resize', this.requestUpdate.bind(this));
    },
    init: function () {
        this.addEventListeners();
        this.requestUpdate();
    }
};

jQuery(function ($) {
    Monogram.Sticky.init();
});
