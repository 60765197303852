/*global Promise, console */

/**
 * Code to execute as early as possible.
 */

/**
 * OneTrust code breaks non-prod monogram sites, due to use of http:
 * instead of https:.  This is a workaround from OneTrust to "disable"
 * it when user is on staging or dev.
 */
if (location.hostname === 'monogram-stg.al.ge.com' ||
    location.hostname === 'monogram-dev.al.ge.com' ||
    (location.protocol === 'http:' &&
     /(^|\.)(localhost|bs-local\.com)$/.test(location.hostname))) {
    document.cookie = "OptanonConsent=isIABGlobal=false&" +
        "datestamp=Mon+Jun+01+2020+07%3A31%3A00+GMT-0400+(Eastern+Daylight+Time)&" +
        "version=6.0.0&landingPath=NotLandingPage&" +
        "groups=C0001%3A1%2CC0003%3A1%2CC0002%3A1%2CC0004%3A1&hosts=&legInt=&" +
        "consentId=ae141a5c-174c-449d-b821-3176c2f1f2a1&interactionCount=1";
    document.cookie = "OptanonAlertBoxClosed=2020-06-01T11:31:00.108Z";
}

/**
 * Utility functions.
 */

function isStaticTestingEnvironment() {
    return (location.host === "localhost:3000" ||
            /(^|\.)monogram\.localhost$/.test(location.hostname) ||
            location.hostname === "dev.monogram.com" ||
            location.hostname === "test.monogram.com");
}

function isStaticEnvironment() {
    return (location.host === "localhost:3000" ||
            /(^|\.)monogram\.localhost$/.test(location.hostname) ||
            location.hostname === "dev.monogram.com" ||
            location.hostname === "staging.monogram.com" ||
            location.hostname === "prod.monogram.com" ||
            location.hostname === "www.monogram.com" ||
            location.hostname === "test.monogram.com" ||
            location.hostname === "monogram.com");
}

function isNonStaticTestingEnvironment() {
    return !isStaticEnvironment() && /(^|\.)ge\.com$/.test(location.hostname);
}

var Monogram;
if (typeof Monogram === "undefined") {
    Monogram = {};
}

if (!Monogram.settings) {
    Monogram.settings = {};
}

// Be sure to also change _monogram-variables.scss if you change this.
Monogram.settings.transitionDurationMs = 400;

Monogram.breakpoints = {
    'xxsSpecial': 432,          // not a Bootstrap breakpoint
    'xsSpecial': 576,           // not a Bootstrap breakpoint
    'sm': 768,
    'md': 992,
    'lg': 1200,
    'xl': 1680,                 // not a Bootstrap breakpoint
    'xxsmallSpecial': 432,      // not a Bootstrap breakpoint
    'xsmallSpecial': 576,       // not a Bootstrap breakpoint
    'small': 768,
    'medium': 992,
    'large': 1200,
    'xlargeSpecial': 1680       // not a Bootstrap breakpoint
};

if (!Monogram.Utilities) {
    Monogram.Utilities = {};
}

/**
 * This is basically a check for falsiness, but different in that:
 *
 * -   Instead of only the empty string, any string consisting
 *     only of whitespace (`/\s/`) is considered empty.
 *
 * -   The numeric value 0, while falsy, is not considered empty.
 *
 * One might typically use it to check whether an HTML element's
 * attribute value has been specified and is non-empty.
 */
function empty(x) {
    // yes, NaN !== NaN, and is the only value for which that holds
    // true.
    var isLiterallyNaN = (x !== x);

    return x === undefined || x === null || x === false || isLiterallyNaN || !/\S/.test(String(x));
}

function generateUniqueId(id) {
    var count = 1;
    var result;
    while (true) {
        result = id + '-' + count;
        if (!document.getElementById(result)) {
            return result;
        }
        count += 1;
    }
}

function onOrAfterReady(callback) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

function onOrAfterLoad(callback) {
    if (document.readyState === 'complete') {
        callback();
    } else {
        window.addEventListener('load', callback);
    }
}

/**
 * Adds/removes a class to/from the <html> element.
 */
Monogram.Utilities.addThingyOpenClass = function (className) {
    document.documentElement.classList.add(className);
};
Monogram.Utilities.removeThingyOpenClass = function (className) {
    document.documentElement.classList.remove(className);
};

(function () {
    var hostname = location.hostname;
    if (/(?:^|\.)ge\.com/.test(hostname)) {
        Monogram.cookieDomain = "ge.com";
    } else if (/(?:^|\.)monogram\.com/.test(hostname)) {
        Monogram.cookieDomain = "monogram.com";
    } else if (/(?:^|\.)monogram\.localhost/.test(hostname)) {
        Monogram.cookieDomain = "monogram.localhost";
    }
}());

/**
 * HTML Entity encoding.  Was originally three interfaces to two
 * different functions.
 */

var encodeEntities = (function () {
    function decimalEncode(char) {
        return "&#" + char.charCodeAt(0) + ";";
    }
    function decimalSurrogatePairEncode(pair) {
        return "&#" + (0x10000 + (pair.charCodeAt(0) - 0xd800) * 1024 + (pair.charCodeAt(1) - 0xdc00)) + ";";
    }
    var encodeEntities = function (string, /* optional */ options) {
        string = String(string);
        string = string.replace(/&/g, "&amp;");
        string = string.replace(/</g, "&lt;");
        string = string.replace(/>/g, "&gt;");
        string = string.replace(/\'/g, "&#39;"); // not all browsers support &apos;
        string = string.replace(/\"/g, "&quot;");
        string = string.replace(/[\ud800-\udbff][\udc00-\udfff]/g, decimalSurrogatePairEncode);

        // replace CRLF or LF line endings, optionally.
        if (options && options["<br>"]) {
            string = string.replace(/\r?\n/g, "<br>");
        }

        string = string.replace(/[^ -~]/g, decimalEncode);
        return string;
    };
    return encodeEntities;
}());

function decodeEntitiesStripHTML(html) {
    var span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent;
}

Monogram.Utilities.setTransform = function (element, value) {
    element.style.transform = value;
    element.style.webkitTransform = value;
    element.style.msTransform = value;
    element.style.OTransform = value;
};

Monogram.Utilities.delayPromise = function (delay) {
    return function (x) {
        return new Promise(
            function (resolve) {
                return setTimeout(function () {
                    return resolve(x);
                }, 1000);
            }
        );
    };
};

Monogram.Utilities.getUniqueId = function () {
    var id = String(new Date().getTime());
    var random = String(Math.random());
    if (random === '0') {
        random = '0.0';
    }
    id = id + '.' + random.replace(/^0\./, '');
    return id;
};

Monogram.Utilities.formatDate = function (date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    var yyyy = ('0000' + date.getFullYear()).slice(-4);
    var mm   = ('00' + (1 + date.getMonth())).slice(-2);
    var dd   = ('00' + date.getDate()).slice(-2);
    return yyyy + '-' + mm + '-' + dd;
};

Monogram.Utilities.formatTime = function (date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    var hh = ('00' + date.getHours()).slice(-2);
    var mm = ('00' + date.getMinutes()).slice(-2);
    return hh + ':' + mm;
};

(function () {
    var elementData = {};
    Monogram.Utilities.elementData = function (element, key) {
        var id = element.getAttribute('data-element-data-id');
        if (!id) {
            id = Monogram.Utilities.getUniqueId();
            element.setAttribute('data-element-data-id', id);
        }
        var data = elementData[id];
        if (!data) {
            data = elementData[id] = {};
        }
        if (key !== null && key !== undefined) {
            data = elementData[id][key];
            if (!data) {
                data = elementData[id][key] = {};
            }
        }
        return data;
    };
}());
