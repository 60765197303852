/*global jQuery, Monogram */

// Add a data-top-scrollbar attribute to any element, but namely a
// .table-responsive element, to place a top scrollbar above it.
//
// Mainly used on the ginormous table on the product comparison page.
Monogram.TopScrollBar = {
    index: 0,
    topScrollBarData: [],
    addTopScrollBar: function (original) {
        if (original.childElementCount !== 1) {
            return;
        }
        var originalInner = original.firstElementChild;

        var wrapper = document.createElement('div');
        wrapper.classList.add('duplicate-scrollbar-wrapper-outer');
        wrapper.style.overflowX = 'auto';
        wrapper.style.minHeight = '0.01%';

        var wrapperInner = document.createElement('div');
        wrapperInner.classList.add('duplicate-scrollbar-wrapper-inner');
        wrapperInner.style.height = '1px';

        wrapper.appendChild(wrapperInner);
        original.parentNode.insertBefore(wrapper, original);

        var lastScrollLeft = null;
        function createScrollHandler(source, dest) {
            return function (event) {
                var scrollLeft = source.scrollLeft;
                if (lastScrollLeft === null || lastScrollLeft !== scrollLeft) {
                    dest.scrollLeft = scrollLeft;
                }
                lastScrollLeft = scrollLeft;
                if (event) {
                    event.preventDefault();
                }
            };
        }

        function updateWrapperWidth() {
            var $ = jQuery;

            var outerWidth = $(original).width();
            var innerWidth = $(originalInner).outerWidth();
            wrapper.style.width      = outerWidth + 'px';
            wrapperInner.style.width = innerWidth + 'px';
            lastScrollLeft = null;
        }

        var updateOriginalScroll;
        var updateWrapperScroll;

        function handleResize() {
            updateWrapperWidth();
            updateWrapperScroll();
        }

        updateWrapperScroll  = createScrollHandler(original, wrapper);
        updateOriginalScroll = createScrollHandler(wrapper, original);

        updateWrapperWidth();
        updateWrapperScroll();

        original.addEventListener('scroll', updateWrapperScroll);
        wrapper.addEventListener('scroll', updateOriginalScroll);
        window.addEventListener('resize', handleResize);

        this.topScrollBarData[this.index] = {
            update: function () {
                updateWrapperWidth();
                updateWrapperScroll();
            }
        };

        original.setAttribute('data-top-scrollbar-index', this.index);
        this.index += 1;
    },
    recompute: function (original) {
        var $ = jQuery;

        // if called with no argument, find all [data-top-scrollbar]
        // elements in the document and call this method on each of
        // them.
        if (!original) {
            document.querySelectorAll('[data-top-scrollbar]').forEach(function (element) {
                this.recompute(element);
            }.bind(this));
            return;
        }

        var index = parseInt(original.getAttribute('data-top-scrollbar-index'), 10);
        if (isNaN(index)) {
            return;
        }
        var data = this.topScrollBarData[index];
        if (!data) {
            return;
        }
        data.update();
    },
    init: function () {
        document.querySelectorAll('[data-top-scrollbar]').forEach(function (element) {
            this.addTopScrollBar(element);
        }.bind(this));
    }
};

jQuery(function ($) {
    Monogram.TopScrollBar.init();
});
