var encodeEntities = (function () {
    function decimalEncode(char) {
        return "&#" + char.charCodeAt(0) + ";";
    }
    function decimalSurrogatePairEncode(pair) {
        return "&#" + (0x10000 + (pair.charCodeAt(0) - 0xd800) * 1024 + (pair.charCodeAt(1) - 0xdc00)) + ";";
    }
    var encodeEntities = function (string, /* optional */ options) {
        string = String(string);
        string = string.replace(/&/g, "&amp;");
        string = string.replace(/</g, "&lt;");
        string = string.replace(/>/g, "&gt;");
        string = string.replace(/\'/g, "&#39;"); // not all browsers support &apos;
        string = string.replace(/\"/g, "&quot;");
        string = string.replace(/[\ud800-\udbff][\udc00-\udfff]/g, decimalSurrogatePairEncode);

        // replace CRLF or LF line endings, optionally.
        if (options && options["<br>"]) {
            string = string.replace(/\r?\n/g, "<br>");
        }

        string = string.replace(/[^ -~]/g, decimalEncode);
        return string;
    };
    return encodeEntities;
}());

if (!String.prototype.htmlEntities) {
    String.prototype.htmlEntities = function (/* optional */ options) {
        var result = encodeEntities(this, options);
        return result;
    };
}
