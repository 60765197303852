/*global Monogram, jQuery */

/**
 * Monogram Mobile Menu.
 */

Monogram.MobileMenu = {
    addEventListeners: function () {
        var $ = jQuery;

        $(document).on('show.bs.collapse', '.top-nav--mobile', function (event) {
            Monogram.Utilities.addThingyOpenClass('top-nav-menu-mobile-is-active');
        });
        $(document).on('hidden.bs.collapse', '.top-nav--mobile', function (event) {
            Monogram.Utilities.removeThingyOpenClass('top-nav-menu-mobile-is-active');
        });

        $(document).on('show.bs.collapse', '.top-nav-search', function (event) {
            Monogram.Utilities.addThingyOpenClass('top-nav-search-is-active');
        });
        $(document).on('hidden.bs.collapse', '.top-nav-search', function (event) {
            Monogram.Utilities.removeThingyOpenClass('top-nav-search-is-active');
        });
    },
    init: function () {
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.MobileMenu.init();
});
