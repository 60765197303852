/*global CharacterData */

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map
if (!Array.prototype.map) {
    Array.prototype.map = function (callback /*, thisArg*/ ) {
        var T, A, k;
        /*jshint -W116 */
        if (this == null) {
            throw new TypeError('this is null or not defined');
        }
        /*jshint +W116 */
        var O = Object(this);
        /*jshint -W016 */
        var len = O.length >>> 0;
        /*jshint +W016 */
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        if (arguments.length > 1) {
            T = arguments[1];
        }
        A = new Array(len);
        k = 0;
        while (k < len) {
            var kValue, mappedValue;
            if (k in O) {
                kValue = O[k];
                mappedValue = callback.call(T, kValue, k, O);
                A[k] = mappedValue;
            }
            k++;
        }
        return A;
    };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/ReduceRight
if ('function' !== typeof Array.prototype.reduceRight) {
    Array.prototype.reduceRight = function (callback /*, initialValue*/ ) {
        'use strict';
        if (null === this || 'undefined' === typeof this) {
            throw new TypeError('Array.prototype.reduce called on null or undefined');
        }
        if ('function' !== typeof callback) {
            throw new TypeError(callback + ' is not a function');
        }
        /*jshint -W016 */
        var t = Object(this),
            len = t.length >>> 0,
            k = len - 1,
            value;
        /*jshint +W016 */
        if (arguments.length >= 2) {
            value = arguments[1];
        } else {
            while (k >= 0 && !(k in t)) {
                k--;
            }
            if (k < 0) {
                throw new TypeError('Reduce of empty array with no initial value');
            }
            value = t[k--];
        }
        for (; k >= 0; k--) {
            if (k in t) {
                value = callback(value, t[k], k, t);
            }
        }
        return value;
    };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
if (!Array.prototype.every) {
    Array.prototype.every = function (callbackfn, thisArg) {
        'use strict';
        var T, k;
        /*jshint -W116 */
        if (this == null) {
            throw new TypeError('this is null or not defined');
        }
        /*jshint +W116 */
        var O = Object(this);
        /*jshint -W016 */
        var len = O.length >>> 0;
        /*jshint +W016 */
        if (typeof callbackfn !== 'function') {
            throw new TypeError();
        }
        if (arguments.length > 1) {
            T = thisArg;
        }
        k = 0;
        while (k < len) {
            var kValue;
            if (k in O) {
                kValue = O[k];
                var testResult = callbackfn.call(T, kValue, k, O);
                if (!testResult) {
                    return false;
                }
            }
            k++;
        }
        return true;
    };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement, fromIndex) {
        var k;
        /*jshint -W116 */
        if (this == null) {
            throw new TypeError('"this" is null or not defined');
        }
        /*jshint +W116 */
        var o = Object(this);
        /*jshint -W016 */
        var len = o.length >>> 0;
        /*jshint +W016 */
        if (len === 0) {
            return -1;
        }
        /*jshint -W016 */
        var n = fromIndex | 0;
        /*jshint +W016 */
        if (n >= len) {
            return -1;
        }
        k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        while (k < len) {
            if (k in o && o[k] === searchElement) {
                return k;
            }
            k++;
        }
        return -1;
    };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/lastIndexOf
if (!Array.prototype.lastIndexOf) {
    Array.prototype.lastIndexOf = function (searchElement /*, fromIndex*/ ) {
        'use strict';
        if (this === void 0 || this === null) {
            throw new TypeError();
        }
        /*jshint -W016 */
        var n, k,
            t = Object(this),
            len = t.length >>> 0;
        /*jshint +W016 */
        if (len === 0) {
            return -1;
        }
        n = len - 1;
        if (arguments.length > 1) {
            n = Number(arguments[1]);
            /*jshint -W116 */
            if (n != n) {
                n = 0;
            } else if (n != 0 && n != (1 / 0) && n != -(1 / 0)) {
                n = (n > 0 || -1) * Math.floor(Math.abs(n));
            }
            /*jshint +W116 */
        }
        for (k = n >= 0 ? Math.min(n, len - 1) : len - Math.abs(n); k >= 0; k--) {
            if (k in t && t[k] === searchElement) {
                return k;
            }
        }
        return -1;
    };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/fromCodePoint
// modified:
// - to move some var declarations out of a try block
if (!String.fromCodePoint) {
    (function () {
        var defineProperty = (function () {
            var object, $defineProperty, result;
            try {
                object = {};
                $defineProperty = Object.defineProperty;
                result = $defineProperty(object, object, object) && $defineProperty;
            } catch (error) {}
            return result;
        }());
        var stringFromCharCode = String.fromCharCode;
        var floor = Math.floor;
        var fromCodePoint = function (_) {
            var MAX_SIZE = 0x4000;
            var codeUnits = [];
            var highSurrogate;
            var lowSurrogate;
            var index = -1;
            var length = arguments.length;
            if (!length) {
                return "";
            }
            var result = "";
            while (++index < length) {
                var codePoint = Number(arguments[index]);
                /*jshint -W116 */
                if (!isFinite(codePoint) || codePoint < 0 || codePoint > 0x10FFFF ||
                    floor(codePoint) != codePoint) {
                    throw RangeError("Invalid code point: " + codePoint);
                }
                /*jshint +W116 */
                if (codePoint <= 0xFFFF) {
                    codeUnits.push(codePoint);
                } else {
                    codePoint -= 0x10000;
                    /*jshint -W016 */
                    highSurrogate = (codePoint >> 10) + 0xD800;
                    /*jshint +W016 */
                    lowSurrogate = (codePoint % 0x400) + 0xDC00;
                    codeUnits.push(highSurrogate, lowSurrogate);
                }
                /*jshint -W116 */
                if (index + 1 == length || codeUnits.length > MAX_SIZE) {
                    result += stringFromCharCode.apply(null, codeUnits);
                    codeUnits.length = 0;
                }
                /*jshint +W116 */
            }
            return result;
        };
        if (defineProperty) {
            defineProperty(String, "fromCodePoint", {
                "value": fromCodePoint,
                "configurable": true,
                "writable": true
            });
        } else {
            String.fromCodePoint = fromCodePoint;
        }
    }());
}

// https://developer.mozilla.org/en-US/docs/Web/API/NonDocumentTypeChildNode/nextElementSibling
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('nextElementSibling')) {
            return;
        }
        Object.defineProperty(item, 'nextElementSibling', {
            configurable: true,
            enumerable: true,
            get: function () {
                var el = this;
                while ((el = el.nextSibling)) {
                    if (el.nodeType === 1) {
                        return el;
                    }
                }
                return null;
            },
            set: undefined
        });
    });
})([Element.prototype, CharacterData.prototype]);
