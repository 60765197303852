/*global jQuery, Monogram, isStaticEnvironment, console, URLSearchParams */

Monogram.WishListEmailForm = {
    submitHandler: function (event) {
        var form = event.target;
        if (!form.matches('form[data-wishlist-email-form]')) {
            return;
        }
        if (/[\%\#\;<>\=]/.test(form.message.value)) {
            form.message.setCustomValidity("Message cannot contain the following special characters: % # ; < > =\n\nPlease remove or leave message blank.");
            form.message.reportValidity();
            event.preventDefault();
            return;
        }

        this.disableSubmitButtons();
        this.preventClosingModals();
        if (isStaticEnvironment()) {
            if (form.txtFrndEMail1.value === 'bob@example.com') {
                event.preventDefault();
                setTimeout(function () {
                    this.enableSubmitButtons();
                    this.allowClosingModals();
                    this.showSuccessMessage();
                }.bind(this), 5000);
                return;
            }
            event.preventDefault();
            setTimeout(function () {
                this.enableSubmitButtons();
                this.allowClosingModals();
                this.showFailureMessage();
            }.bind(this), 5000);
            return;
        }

        event.preventDefault();
        var params = new URLSearchParams();
        Array.from(form.elements).forEach(function (element) {
            if (/\S/.test(element.value)) {
                params.append(element.name, element.value);
            }
        });
        // application takes application/x-www-form-urlencoded (as
        // provided by URLSearchParams), not multipart/form-data (as
        // provided by FormData).
        fetch('/us/emailwishlist', { method: 'POST', body: params })
            .then(function (response) {
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data && data.result) {
                    this.enableSubmitButtons();
                    this.allowClosingModals();
                    this.showSuccessMessage();
                } else {
                    this.enableSubmitButtons();
                    this.allowClosingModals();
                    this.showFailureMessage();
                }
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.enableSubmitButtons();
                this.allowClosingModals();
                this.showFailureMessage();
            }.bind(this));
    },
    disableSubmitButtons: function () {
        var $ = jQuery;
        document.querySelectorAll('[data-wishlist-email-form] input[type="submit"]').forEach(function (button) {
            button.disabled = true;
            button.dataset.savedValue = button.value;
            button.value = 'Sending...';
        });
    },
    enableSubmitButtons: function () {
        var $ = jQuery;
        document.querySelectorAll('[data-wishlist-email-form] input[type="submit"]').forEach(function (button) {
            button.value = button.dataset.savedValue;
            delete button.dataset.savedValue;
            button.disabled = false;
        });
    },
    preventClosingModals: function () {
        var $ = jQuery;
        $('.modal').each(function (index, modal) {
            var data = $(modal).data('bs.modal');
            if (data) {
                data.options.keyboard = false;
                data.options.backdrop = 'static';
            }
        });
        document.querySelectorAll('.modal button.close').forEach(function (element) {
            element.style.display = 'none';
        });
    },
    allowClosingModals: function () {
        var $ = jQuery;
        $('.modal').each(function (index, modal) {
            var data = $(modal).data('bs.modal');
            if (data) {
                data.options.keyboard = true;
                data.options.backdrop = true;
            }
        });
        document.querySelectorAll('.modal button.close').forEach(function (element) {
            element.style.display = '';
        });
    },
    showSuccessMessage: function () {
        var $ = jQuery;
        var $modals = $('.modal.in');
        var count = $modals.length;
        if (count === 0) {
            $('.wishlist-email-success-modal').modal('show');
            return;
        }
        var handler = function () {
            count -= 1;
            if (count === 0) {
                $('.wishlist-email-success-modal').modal('show');
                $modals.off('hidden.bs.modal', handler);
            }
        };
        $modals.on('hidden.bs.modal', handler);
        $modals.modal('hide');
    },
    showFailureMessage: function () {
        var $ = jQuery;
        var $modals = $('.modal.in');
        var count = $modals.length;
        if (count === 0) {
            $('.wishlist-email-failure-modal').modal('show');
            return;
        }
        var handler = function () {
            count -= 1;
            if (count === 0) {
                $('.wishlist-email-failure-modal').modal('show');
                $modals.off('hidden.bs.modal', handler);
            }
        };
        $modals.on('hidden.bs.modal', handler);
        $modals.modal('hide');
    },
    addEventListeners: function () {
        document.addEventListener('submit', function (event) {
            this.submitHandler(event);
        }.bind(this));

        // due to the additional validation added to the submit event...
        document.addEventListener('input', function (event) {
            var input = event.target;
            if (!input.matches('[data-wishlist-email-form] textarea')) {
                return;
            }
            input.setCustomValidity('');
        });
    },
    init: function () {
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.WishListEmailForm.init();
});
