/*global Monogram, jQuery, console */

(function ($) {
    Monogram.CustomPanelPlanner = {
        slickOptions: {
            infinite: false,
            mobileFirst: true,
            swipeToSlide: true,
            slidesToShow: 1,
            variableWidth: true,
        },

        init: function () {
            this.addEventListeners();
            this.initSlickSliders();
        },

        addEventListeners: function () {
            // triggered by clicking on a photo or text
            $(document).on('click', '[data-cpp-select][data-cpp-panel-style]', this.itemSelectHandler.bind(this));

            // triggered by clicking a "See Models" button
            $(document).on('click', '[data-cpp-see-models][data-cpp-panel-style]', this.itemSeeModelsHandler.bind(this));

            // triggered by changing the slide
            $(document).on('afterChange', '[data-cpp-slick-slider]', this.slickAfterChangeHandler.bind(this));

            // triggered by (re)initialization of slick slider
            $(document).on('init', '[data-cpp-slick-slider]', this.slickInitHandler.bind(this));
            $(document).on('reInit', '[data-cpp-slick-slider]', this.slickReInitHandler.bind(this));
        },

        initSlickSliders: function () {
            $('[data-cpp-slick-slider]').slick(this.slickOptions);
        },

        // triggered by clicking on a photo or text inside a slick slide.
        itemSelectHandler: function (event) {
            this.selectSlickSlideByContainedElement(event.target);
        },

        // triggered by clicking a "See Models" button inside a slick slide.
        itemSeeModelsHandler: function (event) {
            this.selectSlickSlideByContainedElement(event.target);
        },

        // triggered by changing the slide
        slickAfterChangeHandler: function (event, slickObject, currentSlide) {
            var panelStyle = this.getPanelStyleByIndex(event.target, currentSlide);
            this.selectModelsByPanelStyle(panelStyle);
        },

        // triggered by (re)initialization of slick slider
        slickInitHandler: function (event, slickObject) {
            var panelStyle = this.getSelectedPanelStyle(event.target, slickObject);
            this.selectModelsByPanelStyle(panelStyle);
        },
        slickReInitHandler: function (event, slickObject) {
            var panelStyle = this.getSelectedPanelStyle(event.target, slickObject);
            this.selectModelsByPanelStyle(panelStyle);
        },

        // takes an element; selects which slick slide it's in
        selectSlickSlideByContainedElement: function (element) {
            var slickIndex = this.getSlickIndexByContainedElement(element);
            if (isNaN(slickIndex)) {
                console.error('not in a slick slide');
                return;
            }
            this.selectSlickSlideByIndex(element, slickIndex);
        },

        // takes a numeric index; selects which slide it's in
        selectSlickSlideByIndex: function (element, index) {
            var slider = this.getSlickSlider(element);
            if (!slider) {
                console.error('no slick slide with that index?');
                return;
            }
            $(slider).slick('slickGoTo', index);
        },

        // shows models tab content for the given panel style; hides all others.
        selectModelsByPanelStyle: function (panelStyle) {
            Array.from(document.querySelectorAll('[data-cpp-models]')).forEach(function (element) {
                if (element.getAttribute('data-cpp-panel-style') === panelStyle) {
                    console.log('showing', element);
                    Monogram.EqualHeight.updateEqualHeightCollections(element);
                    $(element).slideDown();
                } else {
                    console.log('hiding', element);
                    $(element).slideUp();
                }
            });
        },

        // takes an element, typically a .slick-slide; returns the
        // name of its panel style.
        getPanelStyleByContainerElement: function (element) {
            var having = element.querySelector('[data-cpp-panel-style]');
            if (!having) {
                return null;
            }
            return having.getAttribute('data-cpp-panel-style');
        },

        // returns the panel style for a slick slider selected by the
        // given index.  takes an element, and finds the slick slider
        // it's inside.
        getPanelStyleByIndex: function (element, index) {
            var slider = element.closest('.slick-slider');
            if (!slider) {
                return null;
            }
            var $slide = $(slider).find('.slick-slide:not(.slick-cloned)').filter(function (i, slide) {
                return Number(slide.getAttribute('data-slick-index')) === index;
            });
            if (!$slide.length) {
                return null;
            }
            return this.getPanelStyleByContainerElement($slide[0]);
        },

        // returns the panel style for a slick slider's current slide.
        // takes an element, and finds the slick slider it's inside.
        getSelectedPanelStyle: function (element, slickObject) {
            return this.getPanelStyleByIndex(element, slickObject.slickCurrentSlide());
        },

        // takes an element; returns the slide index of any slide it's
        // inside.
        getSlickIndexByContainedElement: function (element) {
            var having = element.closest('[data-slick-index]');
            if (!having) {
                return NaN;
            }
            return Number(having.getAttribute('data-slick-index'));
        },

        // takes an element; returns either the slick slider it's in, or
        // the (first) slick slider that's in it.
        getSlickSlider: function (element) {
            return element.closest('.slick-slider') || element.querySelector('.slick-slider');
        }
    };
}(jQuery));

jQuery(function ($) {
    Monogram.CustomPanelPlanner.init();
});
