/*global jQuery, Monogram */

Monogram.LoadingSpinner = {
    get: function () {
        var spinner = document.getElementById('monogram-loading-spinner');
        if (spinner) {
            return (this.spinner = spinner);
        }
        spinner = document.createElement('div');
        spinner.id = 'monogram-loading-spinner';
        spinner.classList.add('loading-spinner');
        // spinner.innerHTML = '<span class="loading-spinner-icon"><i class="fas fa-spinner fa-pulse"></i></span>';
        spinner.innerHTML = '<svg class="loading-spinner-svg" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="loading-spinner-svg-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>';
        document.body.appendChild(spinner);
        return (this.spinner = spinner);
    },
    show: function () {
        var spinner = this.get();
        spinner.classList.add('show');
    },
    hide: function () {
        var spinner = this.spinner;
        if (spinner) {
            spinner.classList.remove('show');
        }
    }
};

// no init code
