/*global jQuery, Monogram, Cookies, empty */

Monogram.WishList = {
    getState: function () {
        var data = Cookies.get('monogramWishList');
        if (data) {
            data = data.toUpperCase().split(',');
        } else {
            data = [];
        }
        this.state = data;
    },
    saveState: function () {
        var data = this.state.join(',').toUpperCase();
        Cookies.set('monogramWishList', data, {
            path: '/',
            domain: Monogram.cookieDomain,
            expires: new Date(Date.now() + /* 1 year */ 31536000000)
        });
    },
    getCount: function () {
        return this.state.length;
    },
    getList: function () {
        var skus = this.state;
        skus.sort();
        return skus;
    },
    add: function (sku) {
        if (!sku) {
            return;
        }
        sku = sku.toUpperCase();
        if (!this.has(sku)) {
            this.state.push(sku);
            this.state.sort();
            this.saveState();
        }
    },
    remove: function (sku) {
        if (!sku) {
            return;
        }
        sku = sku.toUpperCase();
        if (this.has(sku)) {
            this.state = this.state.filter(function (eachSku) {
                return eachSku.toUpperCase() !== sku;
            });
            this.saveState();
        }
    },
    toggle: function (sku) {
        if (!sku) {
            return;
        }
        sku = sku.toUpperCase();
        if (this.has(sku)) {
            this.remove(sku);
            return false;
        } else {
            this.add(sku);
            return true;
        }
    },
    has: function (sku) {
        if (!sku) {
            return;
        }
        sku = sku.toUpperCase();
        return this.state.some(function (eachSku) {
            return eachSku.toUpperCase() === sku;
        });
    },
    clear: function () {
        this.state = [];
        this.saveState();
    },
    updateWishListCounts: function () {
        var count = this.getCount();
        document.querySelectorAll('[data-wishlist-count]').forEach(function (element) {
            element.classList.remove('wishlist-count-unknown');
            element.innerHTML = count;
            if (count) {
                element.classList.remove('wishlist-count-zero');
            } else {
                element.classList.add('wishlist-count-zero');
            }
        }.bind(this));
    },
    updateWishListItemIcons: function (sku) {
        if (!sku) {
            return;
        }
        sku = sku.toUpperCase();
        var has = this.has(sku);
        document.querySelectorAll('[data-wishlist-toggle]').forEach(function (toggle) {
            var eachSku = toggle.getAttribute('data-sku');
            if (!eachSku) {
                return;
            }
            eachSku = eachSku.toUpperCase();
            if (eachSku !== sku) {
                return;
            }
            toggle.querySelectorAll('[data-wishlist-icon]').forEach(function (icon) {
                if (has) {
                    icon.classList.remove('far'); // FontAwesome Regular (hollow)
                    icon.classList.add('fas'); // FontAwesome Solid (filled in)
                } else {
                    icon.classList.remove('fas');
                    icon.classList.add('far');
                }
            }.bind(this));
        }.bind(this));
    },
    updateAllWishListItemIcons: function () {
        document.querySelectorAll('[data-wishlist-toggle]').forEach(function (toggle) {
            var sku = toggle.getAttribute('data-sku');
            if (!sku) {
                return;
            }
            sku = sku.toUpperCase();
            var has = this.has(sku);
            toggle.querySelectorAll('[data-wishlist-icon]').forEach(function (icon) {
                if (has) {
                    icon.classList.remove('far'); // FontAwesome Regular (hollow)
                    icon.classList.add('fas'); // FontAwesome Solid (filled in)
                } else {
                    icon.classList.remove('fas');
                    icon.classList.add('far');
                }
            }.bind(this));
        }.bind(this));
    },
    addEventListeners: function () {
        document.addEventListener('click', function (event) {
            var anchor = event.target.closest('[data-wishlist-toggle]');
            if (!anchor) {
                return;
            }
            var sku = anchor.getAttribute('data-sku');
            if (!sku) {
                return;
            }
            sku = sku.toUpperCase();
            this.toggle(sku);
            if (this.has(sku)) {
                this.trackAdd(sku, anchor);
            } else {
                this.trackRemove(sku, anchor);
            }
            this.updateWishListCounts();
            this.updateWishListItemIcons(sku);
            if (document.body.getAttribute('data-page-type') === 'wishlist' && !this.has(sku)) {
                location.reload();
            }
        }.bind(this));
    },
    trackAdd: function (sku, anchor) {
        this.trackToggle(sku, anchor, true);
    },
    trackRemove: function (sku, anchor) {
        this.trackToggle(sku, anchor, false);
    },
    trackToggle: function (sku, anchor, flag) {
        var prodName = anchor && anchor.getAttribute('data-product-name');
        var dataLayerElement = {
            prodSku: sku
        };
        if (flag) {
            dataLayerElement.event = 'wishlist-add';
        } else {
            dataLayerElement.event = 'wishlist-remove';
        }
        if (!empty(prodName)) {
            dataLayerElement.prodName = prodName;
        } else {
            dataLayerElement.prodName = undefined;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerElement);
    },
    init: function () {
        this.getState();
        this.updateWishListCounts();
        this.updateAllWishListItemIcons();
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.WishList.init();
});
