/*global jQuery, empty, Monogram, console, EventCalendar, calendar, alert, ClientSideInclude */

Monogram.Favicons = {
    init: function () {
        var $ = jQuery;

        // courtesy https://realfavicongenerator.net/
        var html = ('<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=47BRWYaalN">' +
                    '<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=47BRWYaalN">' +
                    '<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=47BRWYaalN">' +
                    '<link rel="manifest" href="/site.webmanifest?v=47BRWYaalN">' +
                    '<link rel="mask-icon" href="/safari-pinned-tab.svg?v=47BRWYaalN" color="#5bbad5">' +
                    '<link rel="shortcut icon" href="/favicon.ico?v=47BRWYaalN">' +
                    '<meta name="msapplication-TileColor" content="#da532c">' +
                    '<meta name="theme-color" content="#ffffff">');

        if (typeof ClientSideInclude !== 'undefined') {
            // We assume this also tests whether there is a header.
            // In turn, we also assume this also tests whether or not we're in an iframe.
            html = ClientSideInclude.replaceReferences(html);
            $('head').append(html);
        }
    }
};

Monogram.SearchInputs = {

    // Take whatever text is populated into the site search input in
    // the "body" (data-page-content-inner) and copy it into any blank
    // search input(s) in the top header.
    init: function () {
        var fromInput = document.querySelector('[data-page-content-inner] [data-site-search-input]');
        if (!fromInput) {
            return;
        }
        if (!/\S/.test(fromInput.value)) {
            return;
        }
        var toInputs = Array.from(document.querySelectorAll('[data-top-header] [data-site-search-input]'));
        if (!toInputs.length) {
            return;
        }
        toInputs.forEach(function (input) {
            if (/\S/.test(input.value)) {
                return;
            }
            input.value = fromInput.value;
        });
    }
};

Monogram.Popovers = {
    init: function () {
        var $ = jQuery;

        /**
         * This allows us to place popover content in a separate, hidden element,
         * instead of writing a long HTML attribute string.
         *
         * Specify data-content-id="<element-id>" instead of data-content="text".
         *
         * This function sets the content to the element's innerHTML.
         */
        $('[data-toggle="popover"]').popover({
            content: function () {
                var anchor = this;
                var id = anchor.getAttribute('data-content-id');
                if (empty(id)) {
                    return '';
                }
                var element = document.getElementById(id);
                if (!element) {
                    return;
                }
                return element.innerHTML;
            }
        });

        // Close any popovers on ESC.
        $(window).on('keydown', function (event) {
            if (event.which === 27 /* Escape key */) {
                $('[data-toggle="popover"]').popover('hide').trigger('blur');
                // The blur is required for the next click on the
                // toggle to function.  Otherwise, first click will
                // not work, but second click will.
            }
        }.bind(this));
    }
};

Monogram.ModelNumberFinder = {
    showContentForCategory: function (mnfElement, categoryValue) {
        // We assume a categoryValue is supplied here.

        // recurse if no mnfElement is supplied
        if (!mnfElement) {
            document.querySelectorAll('[data-mnf]').forEach(function (mnfElement) {
                this.showContentForCategory(mnfElement, categoryValue);
            }.bind(this));
            return;
        }

        // hide all "tabs" except for the selected one
        var elements = mnfElement.querySelectorAll('[data-mnf-for-product-category]');
        elements.forEach(function (element) {
            element.style.display = 'none';
        });
        elements.forEach(function (element) {
            if (element.getAttribute('data-mnf-for-product-category') === categoryValue) {
                element.style.display = 'block';
            }
        });
    },

    updateFromSelectElement: function (mnfElement, select) {

        // recurse if no mnfElement is supplied
        if (!mnfElement) {
            document.querySelectorAll('[data-mnf]').forEach(function (mnfElement) {
                this.updateFromSelectElement(mnfElement, select);
            }.bind(this));
            return;
        }

        // if no select is supplied...
        if (!select) {
            select = mnfElement.querySelector('select[data-mnf-product-category]');
            if (!select) {
                console.error('[data-mnf] contains no <select> element');
                return;
            }
        }

        var categoryValue = select.options[select.selectedIndex].value;
        if (!categoryValue) {
            console.error('selected product category has no value, or value is falsy');
            return;
        }

        this.showContentForCategory(mnfElement, categoryValue);
    },

    updateFromBootstrapDropdown: function (mnfElement, selectedOption) {

        // recurse if no mnfElement is supplied
        if (!mnfElement) {
            document.querySelectorAll('[data-mnf]').forEach(function (mnfElement) {
                this.updateFromBootstrapDropdown(mnfElement, selectedOption);
            }.bind(this));
            return;
        }

        // if no selectedOption is supplied...
        var options;
        if (!selectedOption) {
            options = Array.from(mnfElement.querySelectorAll('a[data-mnf-product-category]'));
            if (!options.length) {
                console.error('no options available');
                return;
            }
            selectedOption = options.find(function (option) {
                return option.classList.contains('active');
            });
            if (!selectedOption) {
                selectedOption = options[0];
            }
        }

        this.showContentForCategory(mnfElement, selectedOption.getAttribute('data-mnf-product-category'));
    },
    updateModelNumberFinder: function (mnfElement) {
        if (this.updateFromSelectElement(mnfElement)) {
            return true;
        }
        if (this.updateFromBootstrapDropdown(mnfElement)) {
            return true;
        }
        console.error('unable to update model number finder content');
        return false;
    },
    onChangeSelectElement: function (event) {
        var select = event.target.closest('select[data-mnf-product-category]');
        if (!select) {
            return;
        }
        var mnfElement = select.closest('[data-mnf]') || document.querySelector('[data-mnf]');
        if (!mnfElement) {
            console.log('no [data-mnf] element');
            return;
        }
        this.updateFromSelectElement(mnfElement, select);
    },
    onClickDropdownOption: function (event) {
        var anchor = event.target.closest('a[data-mnf-product-category]');
        if (!anchor) {
            return;
        }
        var mnfElement = anchor.closest('[data-mnf]') || document.querySelector('[data-mnf]');
        if (!mnfElement) {
            console.log('no [data-mnf] element');
            return;
        }
        this.updateFromBootstrapDropdown(mnfElement, anchor);
    },
    init: function () {
        document.querySelectorAll('[data-mnf]').forEach(function (mnfElement) {
            this.updateModelNumberFinder(mnfElement);
        }.bind(this));
        document.addEventListener('change', this.onChangeSelectElement.bind(this));
        document.addEventListener('click', this.onClickDropdownOption.bind(this));
    }
};

Monogram.EventCalendarPage = {
    init: function () {
        var $ = jQuery;
        if (document.body.getAttribute('data-page-type') !== 'event-calendar') {
            return;
        }
        EventCalendar.loadTimeZoneData(function () {
            $.getScript('/assets2/js/events/event-calendar-data.js', function () {
                calendar.populateEventsPage();
            });
        });
    }
};

Monogram.EventRegistrationPage = {
    init: function () {
        var $ = jQuery;
        if (document.body.getAttribute('data-page-type') !== 'event-registration') {
            return;
        }
        EventCalendar.loadTimeZoneData(function () {
            $.getScript('/assets2/js/events/event-calendar-data.js', function () {
                if (!calendar.populateEventRegistrationPage()) {
                    alert("The event registration form is not being accessed properly.\n\n" +
                          "You will now be redirected to the event schedule page.");
                    if (!/(^|\.)monogram\.localhost$/.test(location.hostname)) {
                        document.location.replace("/events/");
                    }
                }
            });
        });
        if (/(^|\.)monogram\.localhost$/.test(location.hostname)) {
            document.querySelectorAll('[data-event-registration-form]').forEach(function (form) {
                form.action = '/MONOGRAM-2020/post.php';
            });
        }
    }
};

Monogram.EventRegistrationThanksPage = {
    init: function () {
        var $ = jQuery;
        if (document.body.getAttribute('data-page-type') !== 'event-registration-thanks') {
            return;
        }
        EventCalendar.loadTimeZoneData(function () {
            $.getScript('/assets2/js/events/event-calendar-data.js', function () {
                calendar.populateEventRegistrationThanksPage();
            });
        });
    }
};

Monogram.VideoControls = {
    init: function () {
        document.addEventListener('click', function (event) {
            var anchor = event.target;
            if (!anchor.hasAttribute('data-video-play')) {
                return;
            }
            var wrapper = anchor.closest('[data-video-wrapper]');
            if (!wrapper) {
                return;
            }
            var video = wrapper.querySelector('video');
            if (!video) {
                return;
            }
            if (!video.dataset.eventsAdded) {
                video.dataset.eventsAdded = true;
                video.addEventListener('ended', function () {
                    anchor.classList.remove('playing');
                });
                video.addEventListener('pause', function () {
                    anchor.classList.remove('playing');
                });
                video.addEventListener('play', function () {
                    anchor.classList.add('playing');
                });
            }
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        });
    }
};

Monogram.DesignCenterPage = {
    slickOptions: {
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
        swipeToSlide: true
    },
    initSlick: function () {
        var $ = jQuery;
        var selector = '[data-design-center-photo-slider]';
        $(selector).slick(this.slickOptions);
    },
    initEventCalendar: function () {
        var $ = jQuery;
        var eventLocation = document.body.getAttribute('data-event-location');
        if (!eventLocation) {
            return;
        }
        EventCalendar.loadTimeZoneData(function () {
            $.getScript('/assets2/js/events/event-calendar-data.js', function () {
                calendar.populateDesignCenterPage(eventLocation);
            });
        });
    },
    init: function () {
        if (document.body.getAttribute('data-page-type') !== 'monogram-design-center') {
            return;
        }
        this.initSlick();
        this.initEventCalendar();
    }
};

Monogram.UpdateDropdownLabel = {
    updateAllMenus: function () {
        document.querySelectorAll('ul.dropdown-menu').forEach(function (menu) {
            this.updateMenu(menu);
        }.bind(this));
    },
    updateMenu: function (menu) {
        var options = [];
        Array.from(menu.children).forEach(function (item) {
            var anchor = item.querySelector('a[data-update-dropdown-label]');
            if (anchor) {
                options.push(anchor);
            }
        });
        if (!options.length) {
            // This is not an error.  Just that a menu may not be
            // using data-update-dropdown-label.
            return;
        }
        var activeOption = options.find(function (anchor) {
            return anchor.classList.contains('active');
        });
        if (!activeOption) {
            activeOption = options[0];
            activeOption.classList.add('active');
        }
        this.updateMenuFromAnchor(activeOption);
    },
    updateMenuFromAnchor: function (anchor) {
        if (!anchor.hasAttribute('data-update-dropdown-label')) {
            return;
        }
        var list = anchor.closest('ul.dropdown-menu');
        if (!list) {
            return;
        }
        var id = list.getAttribute('aria-labelledby');
        if (!id) {
            return;
        }
        var label = document.getElementById(id.replace(/^\#/, ''));
        if (!label) {
            return;
        }
        label.innerHTML = anchor.innerHTML;
    },
    addEventListeners: function () {
        document.addEventListener('click', function (event) {
            this.updateMenuFromAnchor(event.target);
        }.bind(this));
    },
    init: function () {
        this.updateAllMenus();
        this.addEventListeners();
    }
};

jQuery(function ($) {
    Monogram.SearchInputs.init();
    Monogram.Popovers.init();
    Monogram.ModelNumberFinder.init();
    Monogram.EventCalendarPage.init();
    Monogram.EventRegistrationPage.init();
    Monogram.EventRegistrationThanksPage.init();
    Monogram.VideoControls.init();
    Monogram.DesignCenterPage.init();
    Monogram.Favicons.init();
    Monogram.UpdateDropdownLabel.init();
});
