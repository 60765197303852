/*global jQuery, Monogram, empty, console */

/**
 * Tracking to work with Adswerve.
 *
 * -   For link-clicking: <a data-mg-track="...">
 *     Link text is extracted including alt or title attributes of <img>s.
 *
 *         <a href="..." data-mg-track="header">...</a>
 *         <a href="..." data-mg-track="header,where-to-buy">...</a>
 *
 *     data-mg-track can be one (or more) of the following,
 *     separated by spaces and/or commas:
 *
 *         header                   (navigation)
 *         footer                   (navigation)
 *         where-to-buy
 *         events-interest
 *         service-click
 *
 * -   For form submissions:
 *
 *         <form data-mg-track="...">
 *
 *     data-mg-track can be one of the following:
 *
 *         search-submit
 *         events-registration
 *
 * NOT APPLICABLE
 *     26 - change ZIP Code (NOT FOR MONOGRAM)
 *     33 - contact us chat (NOT FOR MONOGRAM)
 *     36 - chat available (NOT FOR MONOGRAM)
 *     75 - newsletter subscription (OMIT)
 *     34 - contact us click to call (NOT FOR ON MONOGRAM)
 *     67 - product registration start (ON GEA SITE)
 *
 * DONE
 *     22 - navigation - DONE
 *     28 - site search submit
 *     51 - WTB click
 *     77-79 - wishlist add (wishlist.js), remove (wishlist.js), view (HTML) - DONE
 *     29 - search results
 *     43 - compare now
 *     38 - hosted events interest
 *     39 - hosted events registration
 *     53 - schedule service click
 *     25 - search filter
 *
 * THINGS WE MIGHT WANT TO DO
 *
 * -   If data-mg-track attribute value matches /^\s*{.*}\s*$/,
 *     parse it as JSON and use it as the object to add to the
 *     data layer.
 *
 *     This would not affect existing "shortcut" values like
 *     data-mg-track="where-to-buy".
 *
 *     Examples:
 *
 *         data-mg-track='{ "event": "service-click"  }'
 *
 *         data-mg-track='{ "event": "where-to-buy", "wtbButton": "comparison" }'
 *
 *     Note the use of single quotes and double quotes here.
 *     Strict JSON only accepts double-quoted strings.
 *
 * -   If a data-mg-track-assign attribute is specified and
 *     its value matches /^\s*{.*}\s*$/, merge it into an
 *     existing data layer object via Object.assign.  You would
 *     continue to use a "shortcut".
 *
 *     Example:
 *
 *         data-mg-track="where-to-buy" data-mg-track-assign='{ "wtbButton": "comparison" }'
 */

Monogram.Track = {
    init: function () {
        this.addEventListeners();
    },
    addEventListeners: function () {
        document.addEventListener('submit', function (event) {
            var form = event.target.closest('form');
            if (!form) {
                return;
            }
            var value = form.getAttribute('data-mg-track');
            if (empty(value)) {
                return;
            }

            // space- or comma-separated
            var values = value.trim().split(/\s*,\s*|\s+/);

            values.forEach(function (value) {
                switch (value) {
                case 'search-submit':
                    this.trackSiteSearchSubmit(form);
                    break;
                case 'events-registration':
                    this.trackHostedEventRegistrationSubmit(form);
                    break;
                }
            }.bind(this));
        }.bind(this));
        document.addEventListener('click', function (event) {
            var track = event.target.closest('[data-mg-track]');
            if (!track) {
                return;
            }
            var value = track.getAttribute('data-mg-track');
            if (empty(value)) {
                return;
            }

            // space- or comma-separated
            var values = value.trim().split(/\s*,\s*|\s+/);

            values.forEach(function (value) {
                switch (value) {
                case 'header':
                    this.trackHeaderNavigationClick(track);
                    break;
                case 'footer':
                    this.trackFooterNavigationClick(track);
                    break;
                case 'where-to-buy':
                    this.trackWhereToBuyClick(track);
                    break;
                case 'events-interest':
                    this.trackHostedEventInterestClick(track);
                    break;
                case 'service-click':
                    this.trackServiceClick(track);
                    break;
                }
            }.bind(this));
        }.bind(this));
    },
    trackServiceClick: function (anchor) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'service-click'
        });
    },
    trackHostedEventInterestClick: function (anchor) {
        var eventName     = anchor.getAttribute('data-mg-track-event-name');
        var eventLocation = anchor.getAttribute('data-mg-track-event-location');
        var eventDate     = anchor.getAttribute('data-mg-track-event-date');
        if (empty(eventName)) {
            eventName = undefined;
        }
        if (empty(eventLocation)) {
            eventLocation = undefined;
        }
        if (empty(eventDate)) {
            eventDate = undefined;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event':         'events-interest',
            'eventName':     eventName,
            'eventLocation': eventLocation,
            'eventDate':     eventDate
        });
    },
    trackHostedEventRegistrationSubmit: function (form) {
        var eventName     = form.getAttribute('data-mg-track-event-name');
        var eventLocation = form.getAttribute('data-mg-track-event-location');
        var eventDate     = form.getAttribute('data-mg-track-event-date');
        if (empty(eventName)) {
            eventName = undefined;
        }
        if (empty(eventLocation)) {
            eventLocation = undefined;
        }
        if (empty(eventDate)) {
            eventDate = undefined;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event':         'events-registration',
            'eventName':     eventName,
            'eventLocation': eventLocation,
            'eventDate':     eventDate
        });
    },
    trackHeaderNavigationClick: function (anchor) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'navigation',
            navLocation: 'header',
            navElementText: this.linkText(anchor),
            navURL: anchor.href
        });
    },
    trackFooterNavigationClick: function (anchor) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'navigation',
            navLocation: 'footer',
            navElementText: this.linkText(anchor),
            navURL: anchor.href
        });
    },
    trackWhereToBuyClick: function (anchor) {
        var wtbButton = anchor.getAttribute('data-mg-track-where-to-buy');
        if (empty(wtbButton)) {
            wtbButton = undefined;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'where-to-buy',
            wtbButton: wtbButton
        });
    },
    trackSiteSearchSubmit: function (form) {
        var input = form.querySelector('[data-site-search-input]');
        if (!input) {
            input = form.querySelector('input[type="search"], input[type="text"]');
        }
        if (!input) {
            console.info("trackSiteSearchSubmit: cannot find search form input");
            return;
        }
        var searchTerm = input.value;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'search-submit',
            searchTerm: searchTerm
        });
    },
    debug: function () {
        document.querySelectorAll('[data-mg-track="header"], [data-mg-track="footer"]').forEach(function (anchor) {
            console.debug(anchor.href + ' -- ' + this.linkText(anchor));
        }.bind(this));
    },
    linkText: function (anchor) {
        anchor = anchor.cloneNode(/* deep */ true);
        var images = Array.from(anchor.querySelectorAll('img'));
        images.forEach(function (image) {
            var alt   = image.getAttribute('alt');
            var title = image.getAttribute('title');
            var text = !empty(alt) ? alt : !empty(title) ? title : null;
            if (text === null || text === undefined) {
                return;
            }
            var textNode = document.createTextNode('[' + text + ']');
            image.parentNode.replaceChild(textNode, image);
        }.bind(this));
        var result = anchor.textContent;
        result = result.trim();
        return result;
    }
};

jQuery(function ($) {
    Monogram.Track.init();
});
