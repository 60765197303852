/*global Monogram, jQuery, empty, onOrAfterLoad, console */

Monogram.SamePageAnchors = {
    stickyTopNavHeight: function () {
        var stickyTopNav = document.getElementById('top-nav-menu--sticky-desktop');
        if (!stickyTopNav) {
            return 0;
        }
        return stickyTopNav.clientHeight;
    },
    scrollToId: function (id) {
        var element = this.getElement(id);
        if (!element) {
            return false;
        }
        return this.scrollTo(element);
    },
    getElement: function (id) {
        if (empty(id)) {
            return null;
        }
        if (id instanceof Node) {
            return id;
        }
        return document.getElementById(id);
    },
    scrollTo: function (element) {
        var $ = jQuery;
        var rect = element.getBoundingClientRect();
        var y = rect.top + window.pageYOffset - this.stickyTopNavHeight();
        $('html, body').animate({
            scrollTop: y
        }, Monogram.settings.transitionDurationMs);
        return element;
    },
    init: function () {
        // can't do this on hashchange events unfortunately
        // (hashchange not cancelable; browser action
        //
        // NOTE: doesn't capture href="#"
        window.addEventListener('click', function (event) {
            var anchor = event.target.closest('a[href]');
            if (!anchor) {
                return;
            }
            var href = anchor.getAttribute('href');
            if (empty(href) || !/^#/.test(href)) {
                return;
            }
            if (anchor.hasAttribute('data-toggle')) {
                return;
            }
            var id = href.replace(/^#/, '');
            var element = this.scrollToId(id);
            if (element) {
                anchor.blur();
                try {
                    element.focus();
                } catch (e) {
                }
                event.preventDefault();
                history.pushState(null, null, '#' + id);
            }
        }.bind(this));

        onOrAfterLoad(function () {
            var id = location.hash.replace(/^#/, '');
            this.scrollToId(id);
        }.bind(this));
    }
};

jQuery(function ($) {
    Monogram.SamePageAnchors.init();
});
